// ==========================================================================
// Mixins
// ==========================================================================

// Contain floats: nicolasgallagher.com/micro-clearfix-hack/
// ---------------------------------------
.clearfix() {
  zoom: 1;
  &:before,
  &:after { content: ""; display: table; }
  &:after { clear: both; }
}

// Webkit-style focus
// ---------------------------------------
.tab-focus() {
  // Default
  outline: thin dotted @gray-dark;
  // Webkit
  outline-offset: 1px;
}

// Use rems for font sizing
// Leave <body> at 100%/16px
// ---------------------------------------
.font-size(@font-size: 16){
  @rem: round((@font-size / 16), 3);
  font-size: (@font-size * 1px);
  font-size: ~"@{rem}rem";
}

// Font smoothing
// ---------------------------------------
.font-smoothing(@mode: on) when (@mode = on) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.font-smoothing(@mode: on) when (@mode = off) {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
}

// ==========================================================================
// Plyr mixins
// https://github.com/selz/plyr
// ==========================================================================

// <input type="range"> styling
.plyr-range-track() {
  height: @plyr-range-track-height;
  background: transparent;
  border: 0;
  border-radius: (@plyr-range-track-height / 2);
  user-select: none;
}
.plyr-range-thumb() {
  position: relative;
  height: @plyr-range-thumb-height;
  width: @plyr-range-thumb-width;
  background: @plyr-range-thumb-bg;
  border: @plyr-range-thumb-border;
  border-radius: 100%;
  transition: background .2s ease, border .2s ease, transform .2s ease;
  box-shadow: @plyr-range-thumb-shadow;
  box-sizing: border-box;
}
.plyr-range-thumb-active() {
  background: @plyr-range-thumb-active-bg;
  border-color: @plyr-range-thumb-active-border-color;
  transform: scale(@plyr-range-thumb-active-scale);
}