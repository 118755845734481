.default{
  .ce-text {
    margin-bottom: 10px;
    @media (min-width: @screen-sm-min) {
      margin-bottom: @ce-margin;
    }
  }
  .bg-style{
    .ce-text {

      @media (min-width: @screen-sm-min) {
        margin-bottom: @x-xs;
      }
    }
  }

  .ce-text
  {
    >h3,
    >h2,
    >h1
    {
      margin-top: 0;
    }
  }


}

.rk-level1 .related-content .ce-text{
  h1,h2{
    margin-bottom: 0;
  }

  margin-bottom: 20px;
}


.ce-text{
  font-size: 16px;
}

.xs-text-vcenter{
  align-self: center;
  margin-bottom: 0!important;
}

@media  (min-width: @screen-sm-min){
  .sm-text-vcenter{
    align-self: center;
    margin-bottom: 0!important;
  }
}
@media  (min-width: @screen-md-min){
  .sm-text-vcenter{
    align-self: center;
    margin-bottom: 0!important;
  }
}
