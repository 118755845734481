.teaserHeadline() {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin: 20px 0 0 0;
}

.teaser-box {

  > .wrapper > a {
    color: #000;
    text-decoration:none;
    display: block;
  }

  .headline {
    .teaserHeadline;
  }

  .teaser-content {
    a, a:active, a:hover, a:visited {
      text-decoration: inherit;
    }
  }

  .teaser-image figure {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    //-o-transition: left .2s ease, right .2s ease, top .2s ease, bottom .2s ease;
    //-moz-transition: left .2s ease, right .2s ease, top .2s ease, bottom .2s ease;
    //-webkit-transition: left .2s ease, right .2s ease, top .2s ease, bottom .2s ease;
    transition: left .4s cubic-bezier(0.445, 0.050, 0.550, 0.950), right .4s cubic-bezier(0.445, 0.050, 0.550, 0.950), top .4s cubic-bezier(0.445, 0.050, 0.550, 0.950), bottom .4s cubic-bezier(0.445, 0.050, 0.550, 0.950);
  }

  .teaser-image {
    overflow: hidden;
    position: relative;
    background-color: #444444;
    img {
      min-width: 100%;
    }
  }

  &:hover figure {
    &:not(.ce-slideshow .ce-teaserbox figure){
      left: -10px;
      right: -10px;
      top: -10px;
      bottom: -10px;
    }
  }

  .teaser-image:before {
    content: " ";
    display: block;
    padding-top: 62.5%;
  }

  &.news,
  &.style1 {
    .teaser-image{

      background-color: @body-bg;

      &:after {
        border-bottom: 5px solid rgba(44, 134, 195, .8);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: ' ';
      }
      img{
          min-width: auto;
          min-width: initial;
          max-height: 100%;
      }
    }
  }

  &.rk-box,
  &.style2 {

    .teaser-content {
      position: relative;
    }

    .headline {

      font-weight: bold;

      .icon {
        width: 29px;
        height: 29px;
        padding: 0;
        left: 10px;
        top: -42px;
        font-size: 15px;
        content: ' ';
        display: block;
        position: absolute;
      }
    }

    .teaser-image{
      &:before {
        content: " ";
        display: block;
        padding-top: 62.5%;
      }

      .teaser-content{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.3);
        color: #fff;
        padding: 40px;
        text-align: center;
        opacity: 0;

        .teaser-link{
          display: none;
        }
      }

    }

    .teaser-link {
      opacity: 0;
      text-align: center;
      margin-top: 20px;
    }
    .teaser-description,
    .teaser-more {
      opacity: 0;
      padding: @x-xs;

      p {
        text-align: left;
      }
    }

    .btn {
      background-color: @gray-dark;
      color: #ffffff !important;
      padding: 4px 10px!important;
      &,
      &:hover,
      &active,
      &visited {
        color: #ffffff;
      }

    }
  }

  &.teaser-box-toggle {
    cursor: pointer;

    .teaser-more {
      cursor: default;
    }

  }
}

.rk-tabs {
  .teaser-box {
    .teaser-box.rk-box;

    .teaser-image{
      background-color: transparent!important;
    }

    .headline{
      margin-bottom: 10px;
      font-family: @font-family-sans-serif-bolder;
    }

    .teaser-content{
      display: flex;
      align-items: center;
    }
  }
}

.teaser-box-wrapper,
.teaser-box-container {
  position: relative;

}

.ce-teaserbox {
  margin-bottom: 20px;

  .teaser-count {
    display: none;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 100%;
    background-color: @brand-blue;
    color: #fff;
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  &.news{
    time{
      display: block;
      margin-top: 20px;
    }
  }

  &.teaser-image-square{
    .teaser-image:before {
      content: " ";
      display: block;
      padding-top: 77.5%;
    }
  }
  .teaser-box-icon-link,
  .teaser-box-icon-more{
    .btn-more{

      &:after{
        display: none!important;
      }

      .icon{
        height: 24px;
        width: 24px;
        display: inline-block;
        margin-bottom: -6px;
      }
    }
  }

}


  .default {

    &.related-content {

      counter-reset: relatedFooter;

      .teaser-count {
        display: block;

        &:before {
          counter-increment: relatedFooter;
          content: '' counter(relatedFooter);
        }
      }


    }



    .ce-teaserbox {
      a {
        color: inherit;
      }
      .teaser-link {
        color: @brand-blue;
        .btn-more {
          .more-link;
        }


      }

      //&:hover{
      //  .teaser-link {
      //    .btn-more {
      //      &:after {
      //        margin-left: 8px;
      //      }
      //    }
      //  }
      //}

      .teaser-box .teaser-image {
        margin-left: -20px;
        margin-right: -20px;
        @media (min-width: @screen-sm-min) {
          margin-left: 0;
          margin-right: 0;
        }
      }

      @media (min-width: @screen-sm-min) {

        margin-bottom: @ce-margin;

      }
    }

  }


.rk-level1 {
  .default {

    &.light {

      .ce-teaserbox {

        text-align: center;

        &.col-sm-6 {
          .teaser-content {
            padding: 0 60px
          }
        }
        &.col-sm-4 {
          .teaser-content {
            padding: 0 25px
          }
        }
      }

      .teaser-link {
        display: none;
      }
    }

    @media (min-width: @screen-sm-min) {
      .ce-teaserbox {
        margin-bottom: 50px;
      }
    }

  }
}
.products,
.company,
.related-content {
  .ce-teaserbox {
    .teaser-content {
      padding: 0 !important;
      text-align: left;

      .headline {
        .teaserHeadline;
        font-size: 15px !important;
      }

      .teaser-link .btn-more {
        font-size: 13px !important;
      }

      .teaser-description p {
        margin-bottom: 0;
      }
    }

  }
}

.ce-video .content-wrapper .headline {
  .teaserHeadline;
  font-size: 15px !important;
}

.start {
  .ce-video .content-wrapper {

    .headline {
      .teaserHeadline;
      font-size: 23px !important;
      font-weight: normal;

      strong {
        font-weight: normal;
        font-size: 43px;
        line-height: 48px;
      }
    }

    .description{
      font-size: 23px!important;
      line-height: 1.3;
    }
  }
}

.nav-wrapper .additional-content{
  .teaser-description,
  .headline{
    font-size: 13px;
  }
  .teaser-link{
    display: none;
  }

}

.page-links{

  .teaser-box{
    .teaser-link{
      font-weight: bold;
      .icon{
        width: 24px;
        height: 24px;
        position: relative;
        top: 5px;
        float: none;
        display: inline-block;
      }
    }
  }

}



.footer{

  .ce-news-list{

    .ce-teaserbox{
      .teaser-box-container{
        overflow: hidden;
      }
    }

    .teaser-content{
      .news-date,
      .teaser-link,
      .teaser-description{
        display: none;
      }
    }
  }
}
