
.sidebar-nav{
  font-size: 14px;
};

.nav-more-info{
  ul {
    list-style: none inside none;
    margin: 0;
    padding: 0;
  }
  li {
    .icon {
      width: 24px;
      height: 24px;
      float: right;
    }
  }
  a {
    font-weight: 600;
    color: @gray-base;
    padding: 5px 0;
    display: block;

    &:active,
    &:hover,
    &:visited {
      text-decoration: none;
    }
  }
}

.nav-more-info.page-nav {

  a {
    border-bottom: 1px solid #000;
  }
}

.nav-more-info > ul {
  border-style: solid;
  border-width: 1px 0;
  border-color: @gray-lighter;
  padding: 10px 0;
}

.products.level1 aside.info,
.news-article,
header,
.rk-level2{

  .ce-linklist{
    margin-bottom: @x-xs;
  }

  aside.info {
    float: right;
    min-width: 250px;
    @media(min-width: @screen-md-min){
      min-width: 300px;
    }

    > ul {

    }
  }
}
.products.level1 aside.info,
.rk-level2 {
  aside.info {
    position: absolute;
    right: -30px;
  }
}
.news-article{
  aside.info{
    position: static;
    width: 100%;
  }
}


.products.level1 aside.info,
.news-article aside.info,
.page-description aside.info,
.rk-level2 .wrapper.paddings aside.info {

  .btn-toggle{
    display: none;
    opacity: 0;
    background: @brand-blue url('../../assets/img/icon_info.svg') no-repeat;
    padding: 24px;
    z-index: -1;
    right: 20px;
    left: auto;
    float: right;
    width: 0;
    bottom: 0;
    position: absolute;
  }

  &.filelist{
    .btn-toggle{
      background-size: 36px;
      background: @brand-blue url('../../assets/img/icon_download_white_24px.svg') no-repeat center;
    }
  }



  @media (max-width: @screen-xs-max) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;



    display: block;
    opacity: 1;

    .btn-toggle {
      display: block;
      opacity: 1;
      z-index: 1;
    }


    .nav-title{
      display: none;
    }

    ul{
      display: none;
      padding: 0;
      border: 0;
      border-top: 1px solid @gray-border;


      li{
        border-bottom: 1px solid @gray-border;
        a{
          padding: 10px 0;
          font-weight: normal;
        }
      }

    }

    .sidebar-nav {
      position: absolute;
      bottom: 0;
      right: 0;

    }

    &.active {

      top: 0;
      background-color: rgba(0, 0, 0, .5);
      left: 0;
      right: 0;
      display: block;

      .nav-title{
        display: block;
      }

      ul{
        display: block;
      }

      .btn-toggle{

        position: absolute;
        bottom: 0;
        right: 20px;

        //background-image: url(/assets/img/icon_toggle_aside_right.svg);
      }


      .sidebar-nav {
        background-color: #fff;
        left: 0;
        padding: 30px 120px 60px 30px;
      }
    }
  }
}




.ce-teaserbox.page-nav,
.ce-page-nav{



  margin-bottom: @x-xs;

  .icon{
    height: 21px;
    width: 21px;
    display: block;
  }

  a{
    border: 0;
    &:hover{
      text-decoration: none;
      color: @brand-blue;
    }
  }

  .page-link{
    display: block;
    text-align: right;
    position: absolute;
    padding: @x-xs;
    bottom: 0;
    right: 0;
  }

  .headline{
    font-size: @font-size-large !important;
    font-weight: bold;
    margin: 0 0 floor((@x-xs/2)) 0;
  }



  .teaser-box>.wrapper,
  >.wrapper{
    background-color: #fff;
    height: 100%;
    position: relative;
    .page-nav{
      padding: @x-xs @x-sm @x-xs @x-xs;
    }

    .page-nav-image{
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      &:before {
        content: ' ';
        padding-top: 90%;
        display: block;
      }

      figure{
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

        img{
          min-height: 100%;
          height: 100%;
          min-width: unset;
          max-width: none;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .page-nav{
    a{
      padding: 0;
      color: #000;
    }
  }

  @media(min-width: @screen-sm-min){
    .teaser-description,
    ul > li{
      line-height: @headings-line-height;
    }
    >.wrapper>a,
    >.wrapper{
      >.flex-row>{
        height: 100%;
        .col-sm-6{
          max-width: percentage((7/@grid-columns));
          flex-basis: percentage((7/@grid-columns));
          width: percentage((7/@grid-columns));
          +.col-sm-6{
            max-width: percentage((5/@grid-columns));
            flex-basis: percentage((5/@grid-columns));
            width: percentage((5/@grid-columns));
          }
        }
      }
    }
  }
}



.ce-teaserbox.page-nav{

  margin-bottom: @x-xs!important;


  >.wrapper{

    >a{
      color: @text-color;
      &:hover,
      &:active,
      &:visited
      {
        color: inherit;
      }
    }
  }
}




.ce-page-nav,
.ce-teaserbox.page-nav{
  &.small-nav,
  &.page-nav-small{

      .page-nav-image{
        &:before{
          padding-top: 44%!important;
        }
      }

      .page-nav-image{
        &:before{
          padding-top: 44%!important;
        }
      }

    @media (min-width: @screen-sm-min) {
      > .wrapper {
        > a > .flex-row,
        > .flex-row > {
          height: 100%;

          .col-sm-6 {
            max-width: percentage((6/@grid-columns)) !important;
            flex-basis: percentage((6/@grid-columns)) !important;
            width: percentage((6/@grid-columns)) !important;

            + .col-sm-6 {
              max-width: percentage((6/@grid-columns)) !important;
              flex-basis: percentage((6/@grid-columns)) !important;
              width: percentage((6/@grid-columns)) !important;
            }
          }

        }
      }
    }

  }
}
