.rk-level1.rk-plan,
.rk-level2.rk-plan,
.products {
  .ce-tab {

    @media(min-width: @screen-sm-min){
      .content.collapse{
        display: block;
      }
    }


    > .tab-container {
      display: flex;
      background-color: @brand-dark-blue;
      > .tab-navigation {
        flex-basis: percentage((4 / @grid-columns));
        border-right: 1px solid #fff;
        padding: @x-xs 0;
      }
      > .tab-content {
        flex-basis: percentage((8 / @grid-columns));
        color: #fff;
        padding-left: @x-sm;
        p {
          color: #fff;
        }
        .ce-text {
          padding-top: @x-sm;
          padding-bottom: @x-sm;
        }
      }
    }

    .nav-tabs {
      li {
        display: block;
        float: none;
        color: #fff;
        z-index: 1;
        a {
          padding: 5px @x-xs;
          color: inherit;
          font-weight: 600;

          &:focus {
            outline: none;
          }
        }

        &:after {
          content: ' ';
          position: absolute;
          right: -10px;
          top: 50%;
          height: 20px;
          width: 20px;
          background-color: @brand-dark-blue;
          margin-top: -10px;
          transform: rotate(45deg);
          border: 1px solid #fff;
          border-left-color: transparent;
          border-bottom-color: transparent;
          opacity: 0;
          transition: opacity .3s ease;
          z-index: -1;
        }

        &.active {
          position: relative;
          color: @brand-blue;
          &:after {
            opacity: 1;
          }
        }
      }
    }

    .ce-image,
    .ce-text {
      margin-bottom: 0;
      align-self: stretch;
    }

    @media (max-width: @screen-xs-max) {

      .tab-container{
        background-color: transparent;

      }



      .tab-pane{
        display: block;
        margin-bottom: @x-xs;
        background-color: @brand-dark-blue;
      }

      .tab-navigation {
        display: none;
      }

      .toggle {
        padding: @x-xs;
        font-weight: bold;
        display: block;

        span{
          cursor: pointer;
        }
      }

      .tab-content,
      .tab-container > .tab-content {

        padding: 0!important;
        flex-basis: 100%!important;
        width: 100%!important;
        background-color: transparent!important;

        .mobile-accordion>.content{
          padding: 0 20px;

        }

      }

    }

  }

}
.rk-level1.rk-plan,
.rk-level2.rk-plan
{
  .ce-tab {
    > .tab-container {

      background-color: @gradient-gray;
      > .tab-navigation {
        border-right-color: #fff;
      }
      > .tab-content {
        color: @text-color;
        p {
          color: @text-color;
        }
      }
    }

    .nav-tabs {
      li {
        color: @text-color;

        &:after {
          background-color: @gradient-gray;
        }

        &.active {
          color: @brand-blue;
        }
      }
    }

    @media (max-width: @screen-xs-max) {

      .tab-pane{
        background-color: @gradient-gray;
      }
    }

  }

}

.default {
  .light {
    .ce-tab {
      margin-bottom: @ce-margin;
    }
  }
}