#ifs-searchbar,
#ifs-main {
  --color-primary: #3278be;
  --color-secondary: #3278be;
  --second-color: #3278be;
  --main-color: #3278be;
  --main-color-transparent: rgba(68, 126, 151, .5);
  --main-color-dark: #264754;
  --main-color-background: #fff;
  --light-color: #b0d6fc;
  --lighter-color: #cee6fd;
  --lighter-color-transparent: rgba(191, 222, 232, .7);
  --lightest-color: #f5f8f9;
  --facet-button-text-color: #3278be;
  --bottom-tab-hover-color: #daedf1;
  --bottom-tab-selected-color: #e5f1f5;
  --focus-color: #c3dae4;
  --focus-outline: rgba(50, 120, 190, 0.5);
}

#ifs-searchbar .ifs-searchfield-wrapper .aria-only {
  display: none !important;
}

#if-search {
  display: flex !important;
  justify-content: center !important;
}

#ifs-main {

  .ifs-facet.entries-selected button {
    color: var(--facet-button-text-color) !important;
  }


  .ifs-facets-hook button {
    background: none !important;
    border-bottom: 1px solid rgb(209, 209, 209) !important;
  }


  .ifs-facet-entry-direct-select {
    display: none !important;
  }

  .ifs-facet .ifs-facet-entries {
    min-height: auto !important;
  }

  &.ifs-main .ifs-rl .ifs-rl-wrapper .ifs-header-hook {
    border: none !important;
  }

  &.ifs-main .ifs-rl .ifs-rl-wrapper .ifs-naming {
    border: none !important;
  }

  &.ifs-main .ifs-rl .ifs-rl-wrapper .ifs-naming .ifs-resultlist-hook > .ifs-hitlist-wrapper {
    height: 100%;
    margin-left: 20px;
  }


  .ifs-rl .ifs-hitlist .ifs-media > .ifs-media-body > .ifs-media-second-row {
    display: block !important;
  }


  .ifs-facet h3 {
    font-size: 1rem
  }

  .ifs-hitlist .ifs-media > .ifs-media-body {
    flex-basis: 100%;
  }

  .ifs-resultlist-hook > .ifs-hitlist-wrapper {
    margin-left: 20px;
  }

  .ifs-loadmore-indicator .ifs-btn-loadmore {
    display: inline-flex !important;
  }

  .ifs-rl .ifs-hitlist .ifs-media {
    background-color: white !important;
  }

  .ifs-media-body .ifs-title {
    font-size: 18px !important;
  }

  .ifs-media-second-row {
    width: 100% !important;
  }

  .ifs-facet-body {
    border: none;
  }

  .ifs-searchbar{
    display: block;
    margin: 0 auto;
    max-width: 480px;
    position: relative;
    padding: 0;

  }


}


li .nav-downloads-quicklink {
  padding-top: 6px !important;
}


.subnav-container {
  border: none !important;

}

#ifs-bottom-tabs-container {
  display: none !important;
}

.ifs-sb .ifs-show-selected-tags {
  margin-top: 0px !important;
}


.search-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgb(83, 83, 83);
  background-color: rgb(245, 245, 245) !important;
  height: 32px !important;
  padding-left: 10px !important;
}

.searchbar-container {

  #navbar-top &{
    position: static;
  }


  @media (min-width: @grid-float-breakpoint) {
    display: flex !important;
    justify-content: center !important;
  }


  .ifs-wrapper {



    #ifs-searchbar{
      padding: 0;
      position: relative;
    }

    @media (max-width: @grid-float-breakpoint-max) {
      background: #fff;
      box-shadow: 0 7px 9px rgba(0, 0, 0, .1);
      position: absolute;
      left: 0;
      right: 0;


      #ifs-searchbar{
        padding: 20px;

        .wyt-wrapper {
          left: 20px;
          right: 20px;
          width: auto;
        }
      }
    }

    @media (min-width: @grid-float-breakpoint){
      .collapse{
        display: block;
      }
    }


  }


}

#ifs-searchbar{

  border-top: none !important;

  .wyt-wrapper {
    display: block !important;
    position: absolute !important;
    border: none !important;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}


body {
  background-color: #f3f3f1 !important;
}


#ifs-search-hint {
  border: none !important;
}

#ifs-sb-search_input {
  border: none !important;
}

#ifs-sources-field {
  border: none !important;
}

.typeahead__container section {
  border: none !important;
}

#ifs-sb-search_input {

  box-shadow: none !important;
  width: 300px;
}

.ifs-centerfield {
  background-color: rgb(245, 245, 245) !important;
}

.ifs-searchbar {
    width: 100% !important;

  .ifs-main-form {
    padding-top: 10px;
  }

}


.ifs-sb .typeahead__container > .ifs-centerfield > .typeahead__searchfield .ifs-searchfield-wrapper > * input#ifs-sb-search_input,
.ifs-sb .typeahead__container > .ifs-centerfield > .typeahead__searchfield .ifs-searchfield-wrapper > * input#ifs-search-hint {
  height: 32px;
}


.ifs-searchbar-info,
.ifs-button,
.searchbar-container {
  border: none !important;
}

.nav-search-toggle {
  @media (min-width: @grid-float-breakpoint) {
    display: none;
  }
}


.ifmodal-overlay .ifs-media-title-filetype + span,
.ifmodal-wrapper .ifs-media-title-filetype + span,
.ifs-rl .ifs-media-title-filetype + span,
.ifs-sb .ifs-media-title-filetype + span,
.ifs-searchareas .ifs-media-title-filetype + span {
  padding-left: 0px;
  font-size: 16px !important;
}

#ifs-tags,
[data-comp-id="ifs-extended-search"],
.ifs-extended-source-button,
.ifs-searchfield-wrapper .ifs-buttongroup,
.ifs-filter-all-open-close,
.ifs-extended-search {
  display: none !important;
}

.ifmodal-overlay .ifs-naming.ifs-external .gutter,
.ifmodal-wrapper .ifs-naming.ifs-external .gutter,
.ifs-rl .ifs-naming.ifs-external .gutter,
.ifs-sb .ifs-naming.ifs-external .gutter,
.ifs-searchareas .ifs-naming.ifs-external .gutter {
  background: none !important;
  border-left: none !important;
  border-right: none !important;
}


#ifs-searchareas {
  display: none !important;
}


.ifs-didyoumean {
  padding-left: 40px;
  margin-bottom: 1.5rem;
}

.ifs-dym-noresults {
  font-weight: bold;
  font-size: 1.4rem;
  margin: 1rem 0;
}

.ifs-dym-query {
  color: var(--color-primary);
}


div#if-search .typeahead__container:before {
  content: '';
  background: url("/siauv4/bootstrap/assets/img/icon_suche_24px.svg") #f5f5f5 center;
  display: block;
  width: 30px;
  background-size: 78%;
  background-repeat: no-repeat;
}

li[data-wyt-category="websites"] {
  display: none !important;
}
