.default {

  .ce-image {
    margin-bottom: 20px;


    &.image-background {


      &.image-bottom {
        padding: 0;
        margin: 0;

        > .wrapper {
          margin: 0 floor((@grid-gutter-width/2*-1)) floor((@x-xs*-1));
        }
      }

      &.left,
      &.right,
      &.image-left,
      &.image-right {
        @media (min-width: @screen-sm-min) {
          margin-bottom: 0;
        }

        > .wrapper {
          margin-top: (-@grid-gutter-width);
          @media (min-width: @screen-sm-min) {
            margin-bottom: (-@grid-gutter-width);
          }
        }
      }

      &.left,
      &.image-left {

        > .wrapper {
          margin-left: (-@grid-gutter-width);
          @media (max-width: @screen-xs-max) {
            margin-right: (-@grid-gutter-width);
          }
        }
      }

      &.right,
      &.image-right {
        > .wrapper {
          margin-right: (-@grid-gutter-width);
          @media (max-width: @screen-xs-max) {
            margin-left: (-@grid-gutter-width);
          }
        }
      }
    }

    @media (min-width: @screen-sm-min) {
      margin-bottom: @ce-margin;
      .ce-image + .ce-image {
        margin-top: -50px;
      }
    }
    padding-left: 10px;
    padding-right: 10px;
  }

  .page-image {
    .ce-image {
      margin-bottom: 0;

      &:not(.rk360){
        @media (min-width: @screen-md-min) {
          height: 350px;
          overflow: hidden;

          > .wrapper {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }

        }

        @media (min-width: @screen-lg-min) {
          height: 440px;
          overflow: hidden;
        }
      }

    }
  }
}

.rk-man.rk-level2 {
  .page-image {
    .ce-image {
      margin-bottom: 0;


      @media (min-width: @screen-md-min) {
        height: 339px;
        overflow: hidden;
        figure {
          position: relative;

        }
      }

      @media (min-width: @screen-lg-min) {
        height: 339px;
        overflow: hidden;
        figure {
          position: relative;

        }
      }
    }
  }
}


.rk-plan.rk-level1 {
  .page-image {
    .ce-image {
      height: auto;

      > .wrapper {
        transform: none;
        position: static;
      }
    }
  }
}


.ce-image {

  &.center {
    text-align: center;
  }

  .wrapper {
    position: relative;


    .image-content-wrapper {
      margin-left: -10px;
      margin-right: -10px;

      &.after {
        position: static !important;
      }
    }

    @media (max-width: @screen-xs-max) {
      .text-white {
        color: inherit !important;
      }
    }

    @media (max-width: @screen-xs-max) {
      .image-content-wrapper {

        &.after-xs{
          .more-info,
          .text-inverted{
            color: inherit;
          }
        }

        padding: @x-xs;

        &.centered-xs,
        &.bottom-xs,
        &.top-xs,
        &.upper-middle-xs,
        &.middle-xs {
          position: absolute;
          left: floor((@grid-gutter-width/-2));
          right: floor((@grid-gutter-width/-2));
          top: 0;
          bottom: 0;
          display: flex;
        }

        &.centered-xs,
        &.upper-middle-xs,
        &.middle-xs {
          display: flex;
          align-items: center;
          bottom: 0;
        }

        &.bottom-xs {
          align-items: end;
        }

        &.top-xs {
          align-items: start;
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .image-content-wrapper {
        position: absolute;
        left: floor((@grid-gutter-width/-2));
        right: floor((@grid-gutter-width/-2));
        top: 0;
        padding: @x-sm;

        &.centered,
        &.bottom,
        &.top,
        &.upper-middle,
        &.middle {
          display: flex;
          align-items: center;
          bottom: 0;
        }

        &.bottom {
          align-items: end;
        }

        &.upper-middle {
          align-items: start;

          .image-content {
            top: 18%;
            position: relative;
          }
        }

        &.top {
          align-items: start;
        }
      }

      position: relative;

      //.headline {
      //  font-size: 16px;
      //}

    }

    //@media (min-width: @screen-md-min) {
    //.headline {
    //  font-size: 28px;
    //}
    //}

  }
}

.rk-level2 {
  .paddings {
    .ce-image {
      &.fullwidth {
        @media (min-width: @screen-sm-min) {
          > .wrapper {
            margin-left: -@padding-large;
            margin-right: -@padding-large;
          }

          figure {
            margin-left: -16px;
            margin-right: -16px;
          }
        }
      }
    }
  }
}

.products {
  .page-image .ce-image {
    figure {
      position: static;
      top: 0;
    }
  }
}

.ce-slideshow {
  .ce-image {
    padding-left: 0;
    padding-right: 0;

    .default .page-image & {
      height: auto;

      .wrapper {
        top: 0;
        position: static;
        transform: none;
      }

    }

  }

  .news-article &{
    .swiper-slide {
      .image {
        padding: 0;
      }

      .content > .row >.image{
        padding-left: floor((@grid-gutter-width/2));
        padding-right: floor((@grid-gutter-width/2));
      }

    }
  }


}

&.rk360 {

  @media (max-width: @screen-xs-max) {
    h3, .h3 {
      font-size: 22px;
    }
  }

  .image-content {
    position: static;
  }


  .swiper-slide .content .ce-image .image-content-wrapper .more-info {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
    color: inherit;
    position: absolute;

    @media (max-width: @screen-xs-max) {
      bottom: 0;
    }
  }

  .image-content-wrapper {

    &.centered-xs {
      .image-content {
        @media (max-width: @screen-sm-max) {
          padding-left: 52%; // 680/1180

          .rk-level1 &{
            padding-left: 44%;
          }

        }
      }

    }

    .image-content {
      padding-top: 0;
      margin-left: @x-xs;
      margin-right: @x-xs;
      @media (min-width: @screen-sm-min) {
        padding-left: 52%; // 680/1180
        .rk-level1 &{
          padding-left: 44%;
        }
      }
    }
  }
}


.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio-1x1 {
  --bs-aspect-ratio: (100%);
}

.ratio-4x3 {
  --bs-aspect-ratio: (100% * (3/4));
}

.ratio-16x10 {
  --bs-aspect-ratio: (100% * (10/16));
}
.ratio-16x9 {
  --bs-aspect-ratio: (100% * (9/16));
}

.ratio-16x8 {
  --bs-aspect-ratio: (100% * (8/16));
}

.ce-image.ratio>.wrapper {
  position: absolute!important;
  height: 100%;
  left: floor((@grid-gutter-width/2))!important;
  right: floor((@grid-gutter-width/2))!important;
  bottom: 0;
  top: 0!important;
  transform: none!important;

}

.ce-image.ratio{
  height: auto!important;
}


