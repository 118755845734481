// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `@grid-columns`.

.make-flex-grid-columns() {
  // Common styles for all sizes of grid columns, widths 1-12
  .flex-col(@index) { // initial
    @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
    .flex-col((@index + 1), @item);
  }
  .flex-col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
    @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
    .flex-col((@index + 1), ~"@{list}, @{item}");
  }
  .flex-col(1); // kickstart it
}

.float-flex-grid-columns(@class) {
  .col(@index) { // initial
    @item: ~".col-@{class}-@{index}";
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) { // general
    @item: ~".col-@{class}-@{index}";
    .col((@index + 1), ~"@{list}, @{item}");
  }

  .flex-col(1); // kickstart iti
}

.calc-flex-grid-column(@index, @class, @type) when (@type = width) and (@index > 0) {
  .col-@{class}-@{index} {
    width: percentage((@index / @grid-columns));
    -ms-flex-preferred-size: percentage((@index / @grid-columns));
    flex-basis: percentage((@index / @grid-columns));
    max-width: percentage((@index / @grid-columns));
  }
}
/*
.calc-flex-grid-column(@index, @class, @type) when (@type = offset) {
  .flex-col-@{class}-offset-@{index} {
    margin-left: percentage((@index / @grid-columns));
  }
}
*/
// Basic looping in LESS
.loop-flex-grid-columns(@index, @class, @type) when (@index >= 0) {
  .calc-flex-grid-column(@index, @class, @type);
  // next iteration
  .loop-flex-grid-columns((@index - 1), @class, @type);
}

// Create grid for specific class
.make-flex-grid(@class) {
  //.float-flex-grid-columns(@class);
  .loop-flex-grid-columns(@grid-columns, @class, width);
  .loop-flex-grid-columns(@grid-columns, @class, offset);
}

