.ce-news-list{
  &.featured-first.page-1 .ce-teaserbox:first-child {
    width: percentage((8/@grid-columns));
    flex-basis: percentage((8/@grid-columns));
    max-width: percentage((8/@grid-columns));
    margin-right: percentage((4/@grid-columns));
  }

  .ce-teaserbox{
    &.news{
      .teaser-box .teaser-image:before {
        content: " ";
        display: block;
        padding-top: 72.22222%;
      }
    }
  }

  .more-news{

    text-align: center;
    padding-top: 5px;
    a .icon{
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-bottom: -4px;
      margin-right: 10px;

    }
  }
}
