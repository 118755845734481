@triangle-size: 18px;
@triangle-height: ((@triangle-size)*0.8);

.service-searchbar {
  .icon {
    width: 24px;
    height: 24px;

    &.si-search {
      display: block;
    }
  }
}

.service-selection {
  margin-top: @x-sm;


  .service-list {


    &.list-horizontal {

      background-color: @brand-dark-blue;
      color: #fff;

      .service-list-item {
        font-size: @font-size-h3;
        padding: @x-lg @x-sm;
        color: inherit;
        display: block;

        &:after {
          background-image: url('../../assets/img/icon_arrow_down_inverted.svg');
          display: block;
          @media (max-width: @screen-xs-max) {
            margin-left: @x-xs;
            display: inline-block;
          }
        }

        .icon {
          height: 48px;
          text-align: center;

          @media (max-width: @screen-xs-max) {
            display: inline-block;
            width: @font-size-h3-xs;
            height: @font-size-h3-xs;
          }

          img {
            max-height: 100%;
          }
        }


        &:hover {
          text-decoration: none;
          &:after {
            bottom: 28px;
          }
        }

        &.active {
          position: relative;

          &:before {
            display: block;
            content: '';
            position: absolute;
            bottom: ((@triangle-height)/-1);
            left: 50%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: @triangle-height @triangle-size 0 @triangle-size;
            border-color: @brand-dark-blue transparent transparent transparent;
            margin-left: ((@triangle-size)/-1);
          }

          &:after {
            opacity: 0;
          }
        }

        @media (max-width: @screen-md-max) {
          padding: @x-lg @x-xs;
          font-size: @font-size-h3;
        }

        @media (max-width: @screen-sm-max) {
          flex-basis: auto;
          flex-grow: 1;
          padding: @x-lg @x-xs;
          font-size: @font-size-h3;
        }

        @media (max-width: @screen-xs-max) {
          flex-basis: 100%;
          padding: @x-xs;
          text-align: left;
          font-size: @font-size-h3-xs;
        }
      }
    }

  }
}


.service-list {
  margin: 0;
  padding: 0;
  list-style: none;

  .service-list-item {
    cursor: pointer;
    &.active {
      color: @brand-blue;
    }
  }

  &.list-horizontal {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: @screen-sm-min) {

      flex-direction: row;
      flex-wrap: nowrap;

      li {
        flex-basis: 33%;
      }
    }

    .service-list-item {
      display: block;
      text-align: center;
      flex: 1;
      font-size: @font-size-h3-xs;
      font-family: @font-family-sans-serif-bolder;
      position: relative;

      @media (max-width: @screen-sm-max) {
        //flex-basis: 50%;
        margin-bottom: @x-xs;
        > div {
          display: inline-block;
        }
      }

      a {
        display: block;
      }


      &:after {
        background-image: url('../../assets/img/icon_arrow_down.svg');
        position: absolute;
        bottom: 32px;
        height: 32px;
        width: 32px;
        content: '';
        display: none;
        margin-left: -16px;
        left: 50%;
        transition: bottom ease .5s;


        @media (max-width: @screen-xs-max) {
          left: auto;
          position: static;
          margin: 0 auto;
          height: @font-size-h3-xs;
          width: @font-size-h3-xs;
        }


      }


      figure {
        margin-bottom: 10px;

      }
    }
  }

}


.service-content {


  .navbar-toggle {
    display: block;
    float: right;

    .icon-bar {
      background-color: @brand-blue;

      &.icon-close {
        line-height: 12px;
      }

    }

    @media (min-width: @screen-sm-min) {
      display: none;
    }


  }

  .category-actions {
    text-align: right;

    .btn {
      display: inline-block;

      .icon {
        vertical-align: middle;
        display: inline-block;
      }
    }

    @media (min-width: @screen-sm-min) {
      height: 100%;
      position: absolute;
      left: -5px;

      .btn-parent {
        display: table-cell;

        span {
          display: none;
        }
      }
    }

  }


  .service-downloads {

    @media (min-width: @screen-sm-min) {
      .filter-selection.collapse {
        display: block !important;
      }
    }

    .result-count {

      font-size: @font-size-h1-xs;

      .result-count-display + span {
        padding-right: @x-xs;
      }

      .btn {
        font-size: inherit;
      }
    }


    .categories-selection {

      .parent-category {
        border-top: 1px solid @gray-border;
        border-bottom: 1px solid @gray-border;
        padding-top: 4px;
        padding-bottom: 4px;
        color: @brand-blue;
      }

      .headline {


        @media (max-width: @screen-xs-max) {
          margin-top: -10px;

          .icon-close {
            display: none;
            font-size: 22px;
          }

          &[aria-expanded='true'] {
            background-color: @brand-blue;
            margin-left: -40px;
            padding-left: 40px;
            margin-right: -40px;
            padding-right: 40px;
            color: #fff;

            .icon-bar {
              display: none;
            }

            .icon-close {
              display: block;
            }
          }

        }

      }

      @media (min-width: @screen-sm-min) {
        padding: 40px;
        background-color: #fff;

        .headline {
          text-align: center;


        }

        .category-column {
          display: none;

          &.has-children {
            display: block !important;
          }
        }

        &.max-level-1,
        &.max-level-2 {

          .category-column.has-children {
            display: block !important;
          }

        }

        &.max-level-3,
        &.visible-level-3 {

          .category-column {
            display: block !important;

            &.hidden {
              display: none !important;
            }
          }

          .categorie-level2 {
            margin-left: 30px;

            .category-actions {
              display: table !important;

              .btn-root {
                display: none;
              }
            }
          }

          .categorie-level1 {
            display: none !important;
          }

        }

        .parent-category {
          display: none;
        }
      }

      .wrapper {

        @media (min-width: @screen-sm-min) {
          height: 100%;

          > .flex-row {
            height: 100%;
          }
        }


        @media (max-width: @screen-xs-max) {


          padding: 40px;
          background-color: #fff;
          margin-bottom: @x-xs;

          &.wrapper-filter {
            padding: 10px 40px;

          }

          > .wrapper {
            padding: 0;
          }


        }


      }

      .scroll-content {
        height: 300px;
        max-height: 80vh;
      }


    }

    .actions {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .categorie-level0 {

      border-bottom: 1px solid @gray-border;
      margin-bottom: 10px;

      .service-list {
        margin-left: -25px;
        margin-right: -25px;
      }

      .service-list-item {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 70px;

        &:after {
          display: block;

        }

        &.active {
          &:after {
            background-image: url('../../assets/img/icon_arrow_down_blue.svg');
          }
        }
      }

    }

    .category-filter,
    .category-column .categories {
      font-family: @font-family-sans-serif-bolder;
      height: 100%;

      .headline {
        font-size: @font-size-h3-xs;
        padding: 10px 0;
      }

      .service-list-separator {
        padding-top: 10px;
        color: @gray-light;
      }

      .service-list-item {
        padding-top: 4px;
        padding-bottom: 4px;

      }
    }

    @media (min-width: @screen-sm-min) {
      .category-filter,
      .category-column .categories {
        padding-left: 25px;
        padding-right: 25px;
        border-left: 1px solid @gray-border;
      }
    }

  }
}

.service-resultoptions {
  text-align: right;

  .dropdown {
    font-family: @font-family-sans-serif-bolder;
    display: inline-block;
  }
}

.service-results {
  padding-top: @x-xs;
  padding-bottom: @x-xs;
  background-color: #fff;

  .result-group {

    border-bottom: 1px solid @gray-border;
    margin: 10px @padding-large;

    @media (max-width: @screen-xs-max) {
      margin: 10px @x-xs;
    }

    .headline {
      border-bottom: 1px solid @gray-border;
      font-size: @font-size-h3;
      margin-bottom: 10px;
    }

    > .flex-row > .result {
      flex-basis: 100%;
    }
  }

  .result {
    border-bottom: 1px solid @gray-border;
    padding: 10px 0;

    &:last-child {
      border-color: transparent;
      padding-bottom: 0;
    }

    .result-title {
      font-size: @font-size-h4;
      font-family: @font-family-sans-serif-bolder;
    }

    .result-date {
      font-size: @font-size-small;
      font-family: @font-family-sans-serif-bolder;
      color: @gray-light;
    }

    .alert {
      padding: 5px;
      font-size: 12px;
      background-color: transparent;
      margin: 0;
      display: flex;

      &.result-locale-info {
        border-color: transparent;
        color: @brand-info;
      }

      &.result-version-info{
        display: none;
        .document-details&{
          display: flex;
        }
      }



      .glyphicon{
        padding-right: 5px;
        line-height: 1.6;
      }

    }


    .file-preview-info,
    .file-download-info {

      display: inline-block;
      padding-right: 10px;

      a > span {
        border-right: 1.5px solid @link-color;
        padding: 0 8px;

        &:last-child {
          border-color: transparent;
        }
      }
    }

    a {
      font-family: @font-family-sans-serif-bolder;

    }


  }

  .file-preview {
    clear: both;
  }

  .result-thumbnail {
    height: 140px;


    &.no-thumbnail{
      figure{
        display: flex;
        align-items: center;
        justify-content: center;

        img{
          width: 64px;
        }

      }
    }

    figure {
      height: 100%;
      text-align: center;
    }

    img {
      max-height: 100%;
    }
  }


}

.service-content {

  .result-options,
  .results-sorting,
  .result-actions {
    text-align: right;

    .dropdown-toggle{
      .caret{
        margin-left: 10px;
      }
    }

    .dropdown-menu {
      min-width: auto;


      li {
        .action {
          display: block;
        }


      }

      i.icon + span:not(.caret) {
        display: inline-block;
        padding-left: 10px;
      }


      .icon {
        width: (@font-size-base*1.5);
        display: inline-block;
        height: @font-size-base;
        background-size: contain;
      }
    }

    .dropdown {
      display: inline-block;


    }

    .result-action {
      margin-bottom: 10px;
    }
  }

  .action {
    padding-top: 5px;
    padding-bottom: 5px;

    .btn {
      color: @gray-light;
    }
  }

  .icon {
    width: ((@font-size-base)-2);
    height: ((@font-size-base)-2);
    display: inline-block;

    &.icon-locale {
      background-image: url('../../assets/img/icon_world_gray.svg');
      margin-right: 15px;
    }


  }

}


.service-resultoptions {

  > .dropdown {
    padding-left: @x-xs;
  }

  padding: 0 @x-xs;


  .icon {
    .service-content .icon;

    &.icon-filter {
      background-image: url('../../assets/img/icon_filter_gray.svg');
      margin-right: 15px;
    }

    &.icon-sorting {
      background-image: url('../../assets/img/icon_sorting_gray.svg');
      margin-right: 15px;
    }
  }
}
