// Contextual backgrounds

.bg-variant(@color) {
  background-color: @color;
  a&:hover,
  a&:focus {
    background-color: darken(@color, 10%);
  }
}

.bg-transparent {
  background-color: transparent !important;
}

.container-background-colors {
  .bg-gray-blue {
    background-color: @gradient-gray;
  }

  .bg-dark-blue {
    background-color: @brand-dark-blue;
  }

  .bg-sand-gray {
    background-color: @gray-fill;
  }
}

.bg-style {
  .container-background-colors;
}

.ce-column {
  &.bg-style {
    background-color: transparent !important;
  }
}
.ce-column{

  >.wrapper{
    height: 100%;
  }

  &.bg-gray-blue {
    >.wrapper {
      background-color: @gradient-gray;
    }
  }

  &.bg-dark-blue {
    >.wrapper {
      background-color: @brand-dark-blue;
    }
  }

  &.bg-sand-gray {
    >.wrapper {
      background-color: @gray-fill;
    }
  }

  &.bg-style{
    &.bg-gray-blue,
    &.bg-dark-blue,
    &.bg-sand-gray{
      position: relative;
    }
  }
}

