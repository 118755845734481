.icon{

  background-repeat: no-repeat;
  background-size: 100%;

  &.icon-xs {
    height: @x-xs;
    width: @x-xs;
    display: block;
  }

  &.icon-close:before {
    content: '\00D7';
  }

  &.si-document{
    background-image: url('../../assets/img/icon_dokument_24px.svg');
  }
  &.si-country{
    background-image: url('../../assets/img/icon_country.svg');
  }
  &.si-play{
    background-image: url('../../assets/img/icon_play_24px.svg');
  }
  &.si-shopping-cart{
    background-image: url('../../assets/img/icon_shopping_cart.svg');
  }
  &.si-user{
    background-image: url('../../assets/img/icon_benutzer_24px.svg');
  }
  &.si-language{
    background-image: url('../../assets/img/icon_sprache_24px.svg');
  }
  &.si-map{
    background-image: url('../../assets/img/icon_standort_24px.svg');
  }
  &.si-map-black{
    background-image: url('../../assets/img/icon_standort_black_24px.svg');
  }
  &.si-map-outline{
    background-image: url('../../assets/img/icon_standort_outline.svg');
  }
  &.si-search{
    background-image: url('../../assets/img/icon_suche_24px.svg');
  }

  &.si-rk-security{
    background-image: url('../../assets/img/icon_rk_security.svg');
  }
  &.si-rk-smarthome{
    background-image: url('../../assets/img/icon_rk_smarthome.svg');
  }
  &.si-rk-comfort{
    background-image: url('../../assets/img/icon_rk_comfort.svg');
  }
  &.si-rk-design{
    background-image: url('../../assets/img/icon_rk_design.svg');
  }
  &.si-rk-ventilation{
    background-image: url('../../assets/img/icon_rk_ventilation.svg');
  }
  &.si-window-systems{
    background-image: url('../../assets/img/icon_window_systems.svg');
  }
  &.si-ventilation-systems{
    background-image: url('../../assets/img/icon_ventilation_systems.svg');
  }
  &.si-security-systems{
    background-image: url('../../assets/img/icon_security_systems.svg');
  }
  &.si-door-systems{
    background-image: url('../../assets/img/icon_door_systems.svg');
  }
  &.si-sliding-door-systems{
    background-image: url('../../assets/img/icon_sliding_door_systems.svg');
  }
  &.si-smart-systems{
    background-image: url('../../assets/img/icon_smart_systems.svg');
  }
  &.si-rk-upgrade{
    background-image: url('../../assets/img/icon_rk_upgrade.svg');
  }

  &.si-nav-parent{
    background-image: url('../../assets/img/icon_nav_parent.svg');
  }
  &.si-nav-home{
    background-image: url('../../assets/img/icon_nav_home.svg');
  }
  &.si-nav-back{
    background-image: url('../../assets/img/icon_nav_back.svg');
  }

  &.si-youtube{
    background-image: url('../../assets/img/icon_youtube_grey.svg');
  }
  &.si-ok{
    background-image: url('../../assets/img/icon_social_okru.svg');
  }
  &.si-instagram{
    background-image: url('../../assets/img/icon_instagram_grey.svg');
  }

  &.si-facebook{
    background-image: url('../../assets/img/icon_facebook_grey.svg');
  }

  &.si-twitter{
    background-image: url('../../assets/img/icon_twitter_grey.svg');
  }

  &.si-linkedin{
    background-image: url('../../assets/img/icon_linkedin_grey.svg');
  }

  &.si-newsletter{
    background-image: url('../../assets/img/icon_newsletter_grey.svg');
  }


  &.si-newsletter-blue{
    background-image: url('../../assets/img/icon_newsletter_lightblue.svg');
  }

  &.si-close{
    background-image: url('../../assets/img/icon_close.svg');
  }

  &.icon-flag{
    height: 24px;
  }

  &.si-arrow-right{
    background-image: url('../../assets/img/icon_arrow_right.svg');
  }

  &.si-file-zip{
    background-image: url('../../assets/img/icon_file_zip.svg');
  }

  &.si-file{
    background-image: url('../../assets/img/icon_file.svg');
  }

  &.si-contact-person{
      background-image: url('../../assets/img/icon_ansprechpartner_24px.svg');
  }

  &.si-contact-person2{
      background-image: url('../../assets/img/icon_contact.svg');
  }

  &.si-check{
      background-image: url('../../assets/img/icon_check.svg');
  }

  &.si-list{
    background-image: url('../../assets/img/icon_liste_24px.svg');
  }

  &.si-contact-phone{
    background-image: url('../../assets/img/icon_contact2.svg');
  }

  &.si-download{
    background-image: url('../../assets/img/icon_download_24px.svg');
  }

  &.si-download-black {
    background-image: url('../../assets/img/icon_download_black_24px.svg');
  }

  &.si-arrow-down{
    background-image: url('../../assets/img/icon_arrow_down.svg');
  }

  &.si-calendar{
    background-image: url('../../assets/img/icon_calendar.svg');
  }

}

@flags: ad,ar,bd,bj,bw,cg,co,de,ec,fi,gd,gr,hr,iq,jp,kn,lb,lt,md,mm,mw,ni,nz,pk,ro,sc,sl,st,th,tr,ug,ve,za,ae,at,be,bn,by,ch,cr,dj,ee,fj,ge,gt,hu,ir,ke,kp,lc,lu,me,mn,mx,nl,om,pl,rs,sd,sm,sv,tj,tt,us,vn,zm,af,au,bf,bo,bz,ci,cu,dk,eg,fm,gh,gw,id,is,kg,kr,li,lv,mg,mr,my,no,pa,pt,ru,se,sn,sy,tl,tv,uy,vu,zw,ag,az,bg,br,ca,cl,cv,dm,er,fr,gm,gy,ie,it,kh,kw,lk,ly,mh,mt,mz,np,pe,pw,rw,sg,so,sz,tm,tw,uz,ws,al,ba,bh,bs,cd,cm,cy,do,es,ga,gn,hi,il,jm,ki,kz,lr,ma,mk,mu,na,nr,pg,py,sa,si,sr,td,tn,tz,va,xk,am,bb,bi,bt,cf,cn,cz,dz,et,gb,gq,hn,in,jo,km,la,ls,mc,ml,mv,ne,nu,ph,qa,sb,sk,ss,tg,to,ua,vc,ye;

.icon-flag{
  display: inline-block;
  width: auto;
  img{
    max-height: 100%;
  }

  +.icon-flag{
    margin-left: @x-xs;
  }
}



.icon-flag-en{
  background-image:~"url('../../assets/img/flags/gb.png')";
}


.for-each(@name in @flags) {

  .icon-flag-@{name}{
    background-image:~"url('../../assets/img/flags/@{name}.png')";
  }

}

.icon-flag-cs {
  background-image: ~"url('../../assets/img/flags/cz.png')";
}

.icon-flag-da {
  background-image: ~"url('../../assets/img/flags/dk.png')";
}

.icon-flag-el {
  background-image: ~"url('../../assets/img/flags/gr.png')";
}

.icon-flag-sl {
  background-image: ~"url('../../assets/img/flags/si.png')";
}

.icon-flag-uk {
  background-image: ~"url('../../assets/img/flags/ua.png')";
}

.icon-flag-sr {
  background-image: ~"url('../../assets/img/flags/rs.png')";
}

.icon-flag-bs {
  background-image: ~"url('../../assets/img/flags/ba.png')";
}

.icon-flag-et {
  background-image: ~"url('../../assets/img/flags/ee.png')";
}
