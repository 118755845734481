@import (reference) "../buttons.less";

.default {

  .mobile-accordion {
    flex: 1;
    max-width: 100%;

    .toggle {
      display: none;
    }
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    .triangle-left;
    background: none;
    display: none;
    border-color: transparent @gray-dark transparent transparent;
  }
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    .triangle-right;
    background: none;
    display: none;
    border-color: transparent transparent transparent @gray-dark;
  }

  &.start{
    @media(max-width: @screen-xs-max){
      .swiper-button-prev, .swiper-button-next{
        display: none!important;
      }
    }
  }

  .swiper-thumbnail-container {
    .swiper-slide-next,
    .swiper-slide-prev {
      opacity: 0.8;
    }

    .swiper-slide {
      figure {
        height: 100%;
        img {
          max-height: 100%;
        }
      }
    }
  }

  .ce-slideshow[data-slideshow-tabtype="0"],
  .ce-slideshow[data-slideshow-tabtype="dots"], .swiper-thumbnail-container {

    .swiper-button-next, .swiper-button-prev {
      display: block;
    }

    .swiper-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      -o-transition-property: -o-transform;
      -ms-transition-property: -ms-transform;
      transition-property: transform;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }

    .swiper-slide {
      transition: opacity .2s ease;
      opacity: 0;
      &.swiper-slide-visible {
        opacity: 1;
      }

      figure{
        width: 100%;
      }



      a.more-info{
        &:extend(.btn.btn-more-info all);
        &:hover{
          background-color: transparent;
        }

      }
    }

    > .swiper-container {
      padding: 0 40px 45px 40px;

      > .swiper-pagination {
        bottom: 0;
        left: 0;
        right: 0;
        padding: 12px 5px 25px 5px;
        line-height: 0;
        .swiper-pagination-bullet {
          border-radius: 0;
          height: 7px;
          width: 40px;
          margin: 0 5px;
          background-color: @gray-dark;
          &.swiper-pagination-bullet-active {
            background-color: @brand-blue;
          }
        }
      }
    }
  }

  .ce-slideshow[data-slideshow-tabtype="tabs"] {

    .swiper-slide{
      background-color: @gray-fill;
    }

    > .swiper-container > .swiper-pagination {
      position: static;
      margin-right: 1px; //displayfix #7
      margin-bottom: 20px;

      > .wrapper {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        > div {
          -webkit-box-flex: 1 1;
          -moz-box-flex: 1 1;
          -ms-flex: 1 1;
          flex: 1 1;
        }

        .swiper-pagination-tab {
          margin-left: 5px;
          margin-right: 5px;
          position: relative;
          width: auto;
          height: auto;
          display: inline-block;
          border-radius: 0;
          background: transparent;
          opacity: 1;
          border: 1px solid @gray-lighter;

          .tabname {

            padding: 10px 20px;
            @media (min-width: @screen-sm-min) {
              padding: 20px 40px;
            }

          }

          &:after,
          &:before {
            position: absolute;
            content: ' ';
            font-size: 0;
            width: 0;
            height: 0;
            border-style: solid;
            left: 50%;
            bottom: 0;
            margin-left: -5px;
          }

          &:before {
            border-width: 11px 11px 0 11px;
            border-color: @gray-border transparent transparent transparent;
            z-index: 100;
            margin-left: -6px;
            bottom: -10px;

          }

          &:after {
            border-width: 10px 10px 0 10px;
            border-color: #ffffff transparent transparent transparent;
            z-index: 101;
            bottom: -9px;
          }

        }

        .swiper-pagination-bullet-active {

          border: 1px solid @gray-fill;
          background-color: @gray-fill;

          &:before {
            border-color: @gray-fill transparent transparent transparent;
          }

          &:after {
            border-color: @gray-fill transparent transparent transparent;
          }

        }

      }
    }
  }

  .ce-slideshow {

    @media (min-width: @grid-float-breakpoint) {
      margin-bottom: @ce-margin;



      &.slideshow-nested {
        margin-bottom: 0;
        margin-top: 40px;
      }
    }


    .swiper-container {

    }
    .swiper-slide {
      background: #fff;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

      > .content {
        width: 100%;
      }
    }

  }

  .ce-slideshow[data-slideshow-tabtype="thumbnails"] {
    .swiper-slide {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: left;
      -webkit-box-align: start;
      -ms-flex-align: start;
      -webkit-align-items: flex-start;
      align-items: left;

      >.content{
        height: 100%;
        >.row,
        >.flex-row{
          height: 100%;
        }
        .image{
          height: 100%;
          .wrapper{
            height:100%;
          }
        }

      }
    }
  }
}

@media (max-width: @screen-xs-max) {

  .ce-slideshow{
    .swiper-slide{

      //.text-inverted {
      //  color: @text-color;
      //}

      .image-content{
          padding-top: @x-xs;
          font-size: @font-size-large;
        }
      }
  }


  .default .ce-slideshow[data-slideshow-tabtype="tabs"] > .swiper-container {
    .swiper-slide{
      background-color: transparent;
    }
    > .swiper-pagination {
      display: none;
    }

    > .swiper-pagination-tab {
      display: none !important;
    }

    > .swiper-wrapper > .swiper-slide > .mobile-accordion {
      padding: @x-xs;
      width: 100%;
      > .toggle {
        margin-bottom: 10px;
        margin-top: 10px;
        display: block !important;

        background-color: @gray-fill;


        > span,
        > div {
          display: block;
          border: 1px solid @gray-fill;
          width: 100%;
          padding: 20px;
          font-weight: 600;
          font-size: @font-size-base;
          text-align: left;
          cursor: pointer;
        }
      }

      &.active {

        > .toggle {
          > span,
          > div {
            background-color: @brand-blue;
            color: #fff;
            -webkit-transition: background-color 1s ease;
            -moz-transition: background-color 1s ease;
            -o-transition: background-color 1s ease;
            transition: background-color 1s ease;
          }
        }
      }

      > .content {
        display: none;

        &.collapsing,
        &.in {
          display: block;
        }
      }
    }

    > .swiper-wrapper {
      display: block;

    }

  }
}

@media (min-width: @screen-sm-min) {
  .ce-slideshow {

    .swiper-slide {

      &.background-image,
      &.video{

        .content-wrapper{
          >.content{
            padding: @x-sm;
          }
        }


        .content.upper-middle{
          display: flex;
          align-items: center;

          .image-content{
            transform: translateY(-75%);
          }
        }
      }

      &.video{
        .bottom {
          position: absolute;
          bottom: 0;
          left:0;
        }
      }

      &.background-image{
        >.content{
          position: relative;
          >.ce-image>.wrapper>.image-content-wrapper,
          >.image-content-wrapper{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            font-size: @font-size-h3;
            line-height: floor((@font-size-h3 * 1.3));
          }
        }
      }

      .image-content>.wrapper,
      .image-content-wrapper,
      .image {

          padding: @x-sm;


        &.image-top{
          >.wrapper{
            padding: 0;
          }
        }
      }
    }

  }
}


.triangle-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 20px 0;
}

.triangle-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 20px;
}


.start{
  .page-image{
    .ce-slideshow{
      margin-bottom: 0;

      .swiper-container{
        background-color: #fff;
      }
    }
  }
}
