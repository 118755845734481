

.ce-contact {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: @x-sm;

  .contact-image {
    flex-basis: percentage((5 / @grid-columns));
    width: percentage((5 / @grid-columns));
    padding: 0 @x-sm;
    display: flex;
    justify-content: center;
    align-self: baseline;

    .wrapper {
      position: relative;
      max-width: 120px;
      flex: 1;
      &:before {
        content: ' ';
        display: block;
        padding-bottom: 100%
      }
      figure {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        overflow: hidden;
        border-radius: 50%;
        background-color: #ccc;

        img {

        }
      }
    }

  }





  .contact-half{
    .contact-image {
      flex-basis: percentage((6 / @grid-columns));
      width: percentage((6 / @grid-columns));
    }
  }

  &:not(.content-col-right &){


  @media(min-width: @screen-sm-min){
    .contact-info, .contact-address {
      flex-basis: percentage((7 / @grid-columns));
      width: percentage((7 / @grid-columns));
    }
    .contact-address {
      margin-left: percentage((5 / @grid-columns));
    }
    &.col-sm-6{
      .contact-half;
    }
  }
  @media(min-width: @screen-md-min){

    .contact-image {
      flex-basis: percentage((3 / @grid-columns));
      width: percentage((3 / @grid-columns));
    }
    .contact-info, .contact-address {
      flex-basis: percentage((4 / @grid-columns));
      width: percentage((4 / @grid-columns));
    }

    .contact-address {
      margin-left: percentage((1 / @grid-columns));
    }
    &.col-md-6{
      .contact-half;
    }
  }
  }

  &.contact-no-address {
    .contact-address {
      display: none;
    }
  }

  &.contact-no-image {
    .contact-image {
      display: none;
    }

  }

  &.contact-no-image,
  &.contact-no-address {
    .contact-info {
      flex-basis: percentage((6 / @grid-columns));
      width: percentage((6 / @grid-columns));
    }
  }



}


.ce-job-list{
  .contacts{
    padding-top: @x-md;

    .contact-info{
      padding: 0 (@grid-gutter-width/2);
    }
    .contact-address{
      display: none;
    }

    @media (min-width: @screen-sm-min){
      .contact-image {
        flex-basis: percentage((3 / @grid-columns));
        width: percentage((3 / @grid-columns));
      }

      .contact-info{
        flex-basis: percentage((9 / @grid-columns));
        width: percentage((9 / @grid-columns));
      }
    }

    @media (min-width: @screen-md-min){
      .contact-image {
        flex-basis: percentage((4 / @grid-columns));
        width: percentage((4 / @grid-columns));
      }

      .contact-info{
        flex-basis: percentage((8 / @grid-columns));
        width: percentage((8 / @grid-columns));

      }
    }

  }




}

.ad-search-wrapper{
  .twitter-typeahead{
    display: block!important;
  }
}
.ce-contact,
.ce-location{

  .icon-flag{
    margin-right: @x-xs;
  }

  .headline {
    margin: 0;
    font-weight: bold;
  }

  .company{
    font-weight: bold;
  }

  .contact-info {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .name {
      font-weight: bold;
    }

    .email{
      padding-top: (@x-xs/2);
      padding-bottom: (@x-xs/2);
    }
  }

  &.contact-ad{

    .headline{

      &.location{
        font-size: @font-size-large;
        font-weight: bold;
        margin-bottom: @x-xs;
      }
    }

    .name{
      padding-bottom: @x-xs;
    }
    .contact-info{
      flex-basis: 100%;
      width: 100%;
    }
  }


}
