.what(@i) when (mod(@i, 2) = 0) {
  @what: x;
}
.what(@i) when (default()) {
  @what: y;
}

.properties(@prefix, @postfix, @whitespace, @what, @position, @grids, @i: 1) when (@i <= length(@grids)) {

  @grid: extract(@grids,@i);


  .mixin (@whitespace) when (@whitespace = padding) {
    @prop: p;
  }

  .mixin (@whitespace) when (@whitespace = margin) {
    @prop: m;
  }

  .mixin(@whitespace);

  .@{postfix}@{prop}@{prefix}-@{grid} {
    @var: ~"@{what}-@{grid}";
      @{whitespace}-@{position}: @@var!important;
  }

  .properties(@prefix, @postfix, @whitespace, @what, @position, @grids, (@i + 1));
}

.whitespace(@positions, @whitespace: margin, @postfix: ~"", @i: 1) when (@i <= length(@positions)) {

  @class: extract(extract(@positions, @i), 1);
  @position: extract(extract(@positions, @i), 2);

  .what(@i);
  .properties(@class, @postfix, @whitespace, @what, @position, @grids);

  .whitespace(@positions, @whitespace, @postfix, (@i + 1));
}




