//
// Navbars
// --------------------------------------------------

// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.start {
  .navbar {
    margin-bottom: 0px;
  }
}

.navbar {
  position: relative;
  min-height: @navbar-height; // Ensure a navbar always shows (e.g., without a .navbar-brand in collapsed mode)
  //margin-bottom: 30px;
  @media (min-width: @grid-float-breakpoint) {
    //margin-bottom: @navbar-margin-bottom;
    min-height: 83px;
  }
  border: 1px solid transparent;

  // Prevent floats from breaking the navbar
  &:extend(.clearfix all);

  @media (min-width: @grid-float-breakpoint) {
    border-radius: @navbar-border-radius;
  }
}

// Navbar heading
//
// Groups `.navbar-brand` and `.navbar-toggle` into a single component for easy
// styling of responsive aspects.

.navbar-header {
  &:extend(.clearfix all);

  float:left;

  @media (min-width: @grid-float-breakpoint) {
    float: left;
  }
}

// Navbar collapse (body)
//
// Group your navbar content into this for easy collapsing and expanding across
// various device sizes. By default, this content is collapsed when <768px, but
// will expand past that for a horizontal display.
//
// To start (on mobile devices) the navbar links, forms, and buttons are stacked
// vertically and include a `max-height` to overflow in case you have too much
// content for the user's viewport.

.navbar-collapse {
  overflow-x: visible;
  padding-right: @navbar-padding-horizontal;
  padding-left: @navbar-padding-horizontal;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
  &:extend(.clearfix all);
  -webkit-overflow-scrolling: touch;

  &.in {
    overflow-y: auto;
  }

  @media (min-width: @grid-float-breakpoint) {
    width: auto;
    border-top: 0;
    box-shadow: none;

    &.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0; // Override default setting
      overflow: visible !important;
    }

    &.in {
      overflow-y: visible;
    }

    // Undo the collapse side padding for navbars with containers to ensure
    // alignment of right-aligned contents.
    .navbar-fixed-top &,
    .navbar-static-top &,
    .navbar-fixed-bottom & {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  .navbar-collapse {
    //max-height: @navbar-collapse-max-height;

    @media (max-device-width: @screen-xs-min) and (orientation: landscape) {
      max-height: 200px;
    }
  }
}

// Both navbar header and collapse
//
// When a container is present, change the behavior of the header and collapse.

.container,
.container-fluid {
  > .navbar-header,
  > .navbar-collapse {
    margin-right: -@navbar-padding-horizontal;
    margin-left: -@navbar-padding-horizontal;

    @media (min-width: @grid-float-breakpoint) {
      margin-right: 0;
      margin-left: 0;
    }
  }

}

//
// Navbar alignment options
//
// Display the navbar across the entirety of the page or fixed it to the top or
// bottom of the page.

// Static top (unfixed, but 100% wide) navbar
.navbar-static-top {
  z-index: @zindex-navbar;
  border-width: 0 0 1px;

  @media (min-width: @grid-float-breakpoint) {
    border-radius: 0;
  }
}

// Fix the top/bottom navbars when screen real estate supports it
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: @zindex-navbar-fixed;

  // Undo the rounded corners
  @media (min-width: @grid-float-breakpoint) {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;

}

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0; // override .navbar defaults
  border-width: 1px 0 0;
}

// Brand/project name

.navbar-brand {
  float: left;
  padding: @navbar-padding-vertical @navbar-padding-horizontal;
  font-size: @font-size-large;
  line-height: @line-height-computed;
  height: @navbar-height;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  > img {
    display: block;
  }

  @media (min-width: @grid-float-breakpoint) {
    .navbar > .container &,
    .navbar > .container-fluid & {
      margin-left: -@navbar-padding-horizontal;
    }
  }
}

// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggle {

  padding: 9px 10px;
  //padding-right: 170px;
  //.navbar-vertical-align(34px);
  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    outline: 0;
  }

  // Bars
  .icon-bar {
    display: block !important;
    width: 22px;
    height: 2px;
    border-radius: 1px;
  }
  .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  @media (min-width: @grid-float-breakpoint) {
    display: none;
  }
}

// Navbar nav links
//
// Builds on top of the `.nav` components with its own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

.navbar-nav {
  margin: (@navbar-padding-vertical / 2) -@navbar-padding-horizontal;
  width: 100%;

  font-family: @font-family-sans-serif-bolder;

  > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: @line-height-computed;
  }


  > li{
    &.active{
      a:focus{
        outline: none;
      }
    }
  }

  @media (max-width: @grid-float-breakpoint-max) {
    // Dropdowns get custom display when collapsed
    .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      > li > a,
      .dropdown-header {
        padding: 5px 15px 5px 25px;
      }
      > li > a {
        line-height: @line-height-computed;
        &:hover,
        &:focus {
          background-image: none;
        }
      }
    }
  }

  // Uncollapse the nav
  @media (min-width: @grid-float-breakpoint) {

    margin: 0;

    > li {
      float: left;
      > a {
        padding-top: @navbar-padding-vertical;
        padding-bottom: @navbar-padding-vertical;
      }
    }
  }
}

.nav-wrapper {


  .inactive{
    display: none;
    opacity: 0;
  }

  .additional-content{
    .default.light > .wrapper.paddings{
      padding: 0;
    }
  }

  .btn-open-link{
    display: none;
    background-image: url('../../assets/img/icon_arrow_right.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .nav-tabs > li span.nav-subtitle,
  .navbar-nav > li span.nav-subtitle {
    font-size: 12px;
    display: block;
  }

  @media (max-width: @screen-xs-max) {

    .additional-content{
      display: none;
    }

    .open-link{
      .btn-open-link{
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -10px;
      }
    }
  }




  // Subnav Tablet Desktop
  .subnav-container {
    display: block;
    opacity: 1;

    .nav-wrapper {
      display: none;
      opacity: 0;

      &.visible {
        opacity: 1;
        display: block;
      }
    }

    li .subnav-parent.active {
      color: @navbar-default-link-active-color;
    }

  }

  @media (min-width: @grid-float-breakpoint) {

    .additional-content {
      width: 66%;
      float: left;
      padding: 0 10px;
      border-left: 1px solid #ccc;
    }

    .subnav-container {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      z-index: 1;
      position: relative;
      border-top: transparent 1px solid;

      li.divider {
        content: ' ';
        font-size: 0;
        padding: 10px;
      }

      li.title {
        font-family: @font-family-sans-serif-bolder;
        font-weight: bold;
        cursor: default;
        padding: 10px 15px;
      }

      li.title:first-child {
        padding: 0 15px 10px;
      }

      &.level1 {

        position: absolute;
        background-color: #fff;
        left: 0;
        right: 0;
        margin-left: -1px;
        margin-right: -1px;
        overflow: hidden;

        >.nav-wrapper{
          >.navbar-nav{

            padding-top:20px;
            display:flex;

            li{
              max-width: 25%;
            }

          }
        }

        .nav-wrapper{
          padding-bottom: 40px;

        }
        z-index: 50;

        &.active {
          border-top: @gray-lighter 1px solid;

        }

        &.visible {
          border-bottom: @body-bg 1px solid;
          border-top: @gray-lighter 1px solid;
        }

      }


      &.level2 {

        font-size: 13px;
        &.active{
          border-top: 1px solid @gray-border;
          margin-top: 20px;
          padding-top: 10px;
        }


        .nav-wrapper {
          > .navbar-nav {
            width: percentage((4 / @grid-columns));
            float: left;

            li{
              float: none;

              a{

                &.subnav-parent{
                  border-top: 1px solid transparent;
                  border-bottom: 1px solid transparent;
                  &:after{
                    content: '';
                    background-repeat: no-repeat;
                    background-size: 16px 16px;
                    background-position: center;
                    background-image: url('../../assets/img/icon_arrow_right.svg');
                    height: 24px;
                    width: 20px;
                    opacity: 0;
                    right: 0;
                    position: absolute;
                    transition: opacity .5s ease;
                    top: 50%;
                    margin-top: -12px;
                  }
                }

                padding: 4px 0;


              }

              &.active{

                a{

                  &.subnav-parent{
                    //border-color: @gray-border;
                  }


                  &:after{
                    opacity: 1;
                  }

                  &:focus{
                    outline-style: none;
                  }
                }
              }
            }
          }
          >.subnav-container{
            margin-left: percentage((4 / @grid-columns));
          }
        }
      }

      &.level3 {
        .nav-wrapper {
          >.navbar-nav {
            width: 50%;
            float: left;
          }
          >.subnav-container{
            margin-left: 50%;
          }
        }
        .additional-content {
          width: 50%;
        }
      }

      &.level4 {
        .nav-wrapper {
          >.navbar-nav {
            width: 100%;
            float: left;
          }
          >.subnav-container{
            margin-left: 100%;
          }
        }
      }




      .level2>.nav-wrapper,
      #nav-roomcomfort-experience
      {

        >.nav-wrapper {
          width: 20%;
          float: left;
          display: block;
          border-left: @gray-lighter 1px solid;
          &:first-of-type {
            border-left: transparent 1px solid;
          }

          &.visible {
            opacity: 1;
            display: block;
          }
        }

        .navbar-nav li {
          font-size: 13px;
          float: none;

          > a,
          .title {
            padding: 2px 15px;
          }
        }
      }


    }
  }


  .navbar-meta-nav {

    display: flex;

    li, .btn {
      font-size: 14px;
      font-family: @font-family-sans-serif;
    }

    li > a {
      padding: 6px 12px;
    }

    .btn {
      .icon {
        margin-right: 10px;
        position: relative;
        bottom: -6px;
      }

      @media (max-width: @screen-xs-max) {

        .icon {
          margin-right: 0;
        }

        span {
          display: none;
        }
      }
    }


  }

}





// Navbar form
//
// Extension of the `.form-inline` with some extra flavor for optimum display in
// our navbars.

.navbar-form {
  margin-left: -@navbar-padding-horizontal;
  margin-right: -@navbar-padding-horizontal;
  padding: 10px @navbar-padding-horizontal;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  @shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
  .box-shadow(@shadow);

  // Mixin behavior for optimum display
  .form-inline();

  .form-group {
    @media (max-width: @grid-float-breakpoint-max) {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // Vertically center in expanded, horizontal navbar
  .navbar-vertical-align(@input-height-base);

  // Undo 100% width for pull classes
  @media (min-width: @grid-float-breakpoint) {
    width: auto;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    .box-shadow(none);
  }
}

// Dropdown menus

// Menu position and menu carets
.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  .border-top-radius(0);
}

// Menu position and menu caret support for dropups via extra dropup class
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  .border-top-radius(@navbar-border-radius);
  .border-bottom-radius(0);
}

// Buttons in navbars
//
// Vertically center a button within a navbar (when *not* in a form).

.navbar-btn {
  .navbar-vertical-align(@input-height-base);

  &.btn-sm {
    .navbar-vertical-align(@input-height-small);
  }
  &.btn-xs {
    .navbar-vertical-align(22);
  }
}

// Text in navbars
//
// Add a class to make any element properly align itself vertically within the navbars.

.navbar-text {
  .navbar-vertical-align(@line-height-computed);

  @media (min-width: @grid-float-breakpoint) {
    float: left;
    margin-left: @navbar-padding-horizontal;
    margin-right: @navbar-padding-horizontal;
  }
}

// Component alignment
//
// Repurpose the pull utilities as their own navbar utilities to avoid specificity
// issues with parents and chaining. Only do this when the navbar is uncollapsed
// though so that navbar contents properly stack and align in mobile.
//
// Declared after the navbar components to ensure more specificity on the margins.

@media (min-width: @grid-float-breakpoint) {
  .navbar-left {
    .pull-left();
  }

  .navbar-right {
    .pull-right();
    margin-right: -@navbar-padding-horizontal;

    ~ .navbar-right {
      margin-right: 0;
    }
  }
}

// Alternate navbars
// --------------------------------------------------

// Default navbar
.navbar-default {
  background-color: @navbar-default-bg;
  border-color: @navbar-default-border;
  border-bottom: 1px solid @body-bg;
  border-left: 0;
  border-right: 0;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: @grid-float-breakpoint) {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-brand {
    color: @navbar-default-brand-color;
    &:hover,
    &:focus {
      color: @navbar-default-brand-hover-color;
      background-color: @navbar-default-brand-hover-bg;
    }
  }

  .navbar-text {
    color: @navbar-default-color;
  }

  .navbar-nav {
    > li > a {
      color: @navbar-default-link-color;

      &:hover,
      &:focus {
        color: @navbar-default-link-hover-color;
        background-color: @navbar-default-link-hover-bg;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-active-color;
        background-color: @navbar-default-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
        background-color: @navbar-default-link-disabled-bg;
      }
    }

  }

  .navbar-toggle {
    border-color: @navbar-default-toggle-border-color;
    &:hover,
    &:focus {
      background-color: @navbar-default-toggle-hover-bg;
    }
    .icon-bar {
      background-color: @navbar-default-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: @navbar-default-border;
  }

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-default-link-active-bg;
        color: @navbar-default-link-active-color;
      }
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: @navbar-default-link-color;
          &:hover,
          &:focus {
            color: @navbar-default-link-hover-color;
            background-color: @navbar-default-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-active-color;
            background-color: @navbar-default-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-disabled-color;
            background-color: @navbar-default-link-disabled-bg;
          }
        }
      }
    }
  }

  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: @navbar-default-link-color;
    &:hover {
      color: @navbar-default-link-hover-color;
    }
  }

  .btn-link {
    color: @navbar-default-link-color;
    &:hover,
    &:focus {
      color: @navbar-default-link-hover-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
      }
    }
  }
}

// Inverse navbar

.navbar-inverse {
  background-color: @navbar-inverse-bg;
  border-color: @navbar-inverse-border;

  .navbar-brand {
    color: @navbar-inverse-brand-color;
    &:hover,
    &:focus {
      color: @navbar-inverse-brand-hover-color;
      background-color: @navbar-inverse-brand-hover-bg;
    }
  }

  .navbar-text {
    color: @navbar-inverse-color;
  }

  .navbar-nav {
    > li > a {
      color: @navbar-inverse-link-color;

      &:hover,
      &:focus {
        color: @navbar-inverse-link-hover-color;
        background-color: @navbar-inverse-link-hover-bg;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-inverse-link-active-color;
        background-color: @navbar-inverse-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-inverse-link-disabled-color;
        background-color: @navbar-inverse-link-disabled-bg;
      }
    }
  }

  // Darken the responsive nav toggle
  .navbar-toggle {
    border-color: @navbar-inverse-toggle-border-color;
    &:hover,
    &:focus {
      background-color: @navbar-inverse-toggle-hover-bg;
    }
    .icon-bar {
      background-color: @navbar-inverse-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: darken(@navbar-inverse-bg, 7%);
  }

  // Dropdowns
  .navbar-nav {
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-inverse-link-active-bg;
        color: @navbar-inverse-link-active-color;
      }
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display
      .open .dropdown-menu {
        > .dropdown-header {
          border-color: @navbar-inverse-border;
        }
        .divider {
          background-color: @navbar-inverse-border;
        }
        > li > a {
          color: @navbar-inverse-link-color;
          &:hover,
          &:focus {
            color: @navbar-inverse-link-hover-color;
            background-color: @navbar-inverse-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-inverse-link-active-color;
            background-color: @navbar-inverse-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-inverse-link-disabled-color;
            background-color: @navbar-inverse-link-disabled-bg;
          }
        }
      }
    }
  }

  .navbar-link {
    color: @navbar-inverse-link-color;
    &:hover {
      color: @navbar-inverse-link-hover-color;
    }
  }

  .btn-link {
    color: @navbar-inverse-link-color;
    &:hover,
    &:focus {
      color: @navbar-inverse-link-hover-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: @navbar-inverse-link-disabled-color;
      }
    }
  }
}

.navbar-icons {
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-tab {
  .tab-title span {
    font-size: 20px;
    color: @brand-blue;
    font-weight: 500;
    display: inline-block;
    padding: 10px 10px 10px 0;
    cursor: default;
  }
}

.navbar .logo {
  position: absolute;
  width: 170px;
  right: 0;
  top: 0;
}

@media (min-width: @grid-float-breakpoint) {
  #navbar > .nav-wrapper {
    max-height: 60vh;
    margin-right: -28px;

  }

  .nav-controls {
    display: none;
  }

  .navbar .logo {
    right: 40px;
    top: 22px;
  }

  .scroll-info {
    display: block;
    opacity: 0;
    border-top: 1px solid #ccc;
    font-size: 10px;
    padding: 2px 10px;
    text-align: center;
  }

}

.nav-controls {
  margin-top: 10px;

  .icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    top: 7px;
    margin-right: 10px;
  }
  .btn-parent,
  .btn-root {
    float: right;
  }
}


#nav-roomcomfort-experience
{
  padding-top: 15px;
  @media (min-width: @grid-float-breakpoint) {
    .navbar-nav {
      width: 33.33%;
      float: left;
    }
  }
}

.scroll-info {
  display: none;
}
#navbar-top{

  .icon{
    width: 24px;
    height: 24px;
    display: inline-block;
  }

  @media(max-width: @grid-float-breakpoint-max) {



    .navbar-icons>li {
      display: inline-block;

      &.mobile-nav-button{
        display: flex;
      }
    }



  }

    #nav-language{

      position: absolute;
      left: 0;
      z-index: 1;
      background-color: #fff;
      border-top: 1px solid @gray-border;
      padding: ((@grid-gutter-width ));

      @media(min-width: @grid-float-breakpoint) {
        margin-top: 45px;
        padding: @x-xs ((@grid-gutter-width ));
      }

    .navbar-nav {

      margin-left: -5px;

      li a,.info{
        padding: ((@x-xs/4)) 15px!important;
      }

      .info{
        padding-left: 15px;
      }

      li {
        width: 50%;
        float: left;


        .icon-flag {
          margin-right: ((@x-xs/2));
          margin-bottom: -9px;
        }
      }

    }

  }

  .nav-wrapper .subnav-container.level1{
    margin-top: 44px;
  }
}
@media (max-width: @grid-float-breakpoint-max) {
  .navbar-icons{
    >li>.btn{
      display: inline-block;
    }
  }
}

