//
// Panels
// --------------------------------------------------
@import (reference) "glyphicons.less";
// Base class
.panel {
  margin-bottom: @line-height-computed;
}

// Panel contents
.panel-body {
  padding: @panel-body-padding;
  &:extend(.clearfix all);
}

// Optional heading
.panel-heading {
  border-bottom: 1px solid transparent;

  > .dropdown .dropdown-toggle {
    color: inherit;
  }

  > a[data-toggle] {
    color: inherit;
    text-decoration: none;
    display: block;
    position: relative;
    &:hover {
      text-decoration: none;
      color: inherit;
    }

    &:after {
      .glyphicon;
      content: '\e114';
      position: absolute;
      top: 50%;
      margin-top: -7px;
      right: 0;
      display: block;
    }
  }

  &.active {
    > a[data-toggle] {

      &:after {
        content: '\e113';
      }
    }
  }
}

// Within heading, strip any `h*` tag of its default margins for spacing.
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: ceil((@font-size-base * 1.125));
  color: inherit;

  > a,
  > small,
  > .small,
  > small > a,
  > .small > a {
    color: inherit;
  }

}

// Optional footer (stays gray in every modifier class)
.panel-footer {
  padding: @panel-footer-padding;
}

// List groups in panels
//
// By default, space out list group content from panel headings to account for
// any kind of custom content between the two.

.panel {
  > .list-group,
  > .panel-collapse > .list-group {
    margin-bottom: 0;

    .list-group-item {
      border-width: 1px 0;
      border-radius: 0;
    }

    // Add border top radius for first one
    &:first-child {
      .list-group-item:first-child {
        border-top: 0;
        .border-top-radius((@panel-border-radius - 1));
      }
    }

    // Add border bottom radius for last one
    &:last-child {
      .list-group-item:last-child {
        border-bottom: 0;
        .border-bottom-radius((@panel-border-radius - 1));
      }
    }
  }
  > .panel-heading + .panel-collapse > .list-group {
    .list-group-item:first-child {
      .border-top-radius(0);
    }
  }
}

// Collapse space between when there's no additional content.
.panel-heading + .list-group {
  .list-group-item:first-child {
    border-top-width: 0;
  }
}

.list-group + .panel-footer {
  border-top-width: 0;
}

// Tables in panels
//
// Place a non-bordered `.table` within a panel (not within a `.panel-body`) and
// watch it go full width.

.panel {
  > .table,
  > .table-responsive > .table,
  > .panel-collapse > .table {
    margin-bottom: 0;

    caption {
      padding-left: @panel-body-padding;
      padding-right: @panel-body-padding;
    }
  }
  // Add border top radius for first one
  > .table:first-child,
  > .table-responsive:first-child > .table:first-child {
    .border-top-radius((@panel-border-radius - 1));

    > thead:first-child,
    > tbody:first-child {
      > tr:first-child {
        border-top-left-radius: (@panel-border-radius - 1);
        border-top-right-radius: (@panel-border-radius - 1);

        td:first-child,
        th:first-child {
          border-top-left-radius: (@panel-border-radius - 1);
        }
        td:last-child,
        th:last-child {
          border-top-right-radius: (@panel-border-radius - 1);
        }
      }
    }
  }
  // Add border bottom radius for last one
  > .table:last-child,
  > .table-responsive:last-child > .table:last-child {
    .border-bottom-radius((@panel-border-radius - 1));

    > tbody:last-child,
    > tfoot:last-child {
      > tr:last-child {
        border-bottom-left-radius: (@panel-border-radius - 1);
        border-bottom-right-radius: (@panel-border-radius - 1);

        td:first-child,
        th:first-child {
          border-bottom-left-radius: (@panel-border-radius - 1);
        }
        td:last-child,
        th:last-child {
          border-bottom-right-radius: (@panel-border-radius - 1);
        }
      }
    }
  }
  > .panel-body + .table,
  > .panel-body + .table-responsive,
  > .table + .panel-body,
  > .table-responsive + .panel-body {
    border-top: 1px solid @table-border-color;
  }
  > .table > tbody:first-child > tr:first-child th,
  > .table > tbody:first-child > tr:first-child td {
    border-top: 0;
  }
  > .table-bordered,
  > .table-responsive > .table-bordered {
    border: 0;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th:first-child,
        > td:first-child {
          border-left: 0;
        }
        > th:last-child,
        > td:last-child {
          border-right: 0;
        }
      }
    }
    > thead,
    > tbody {
      > tr:first-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
    > tbody,
    > tfoot {
      > tr:last-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
  }
  > .table-responsive {
    border: 0;
    margin-bottom: 0;
  }
}

// Collapsible panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
  margin-bottom: @line-height-computed;

  // Tighten up margin so it's only between panels
  .panel {
    margin-bottom: 0;
    border-radius: @panel-border-radius;

    + .panel {
      margin-top: 5px;
    }
  }

  .panel-heading {
    border-bottom: 0;

    + .panel-collapse > .panel-body,
    + .panel-collapse > .list-group {
      border-top: 1px solid @panel-inner-border;
    }
  }

  .panel-footer {
    border-top: 0;
    + .panel-collapse .panel-body {
      border-bottom: 1px solid @panel-inner-border;
    }
  }
}

.ce-accordion {


  margin-bottom: @x-xs;

  +.ce-accordion{
    margin-top: -@x-xs;
  }


  .panel{
    margin-bottom: 0;
    &.panel-accordion{
      border-bottom: 1px solid @gray-lighter;
    }
  }

  .panel-heading {
    padding-left: 0;
    padding-right: 0;
    transition: border-color .2s ease;

    a[data-toggle]{
      >p{
        margin: 0;
      }

      padding: @x-xs 0;
    }
    h1,h2,h3{
      font-size: @font-size-large!important;
      margin-bottom: @x-xs;
    }
    &.active {
      border-color: transparent;
    }
  }

  .panel-collapse {

    >.row{
      padding-top: @x-xs;
      padding-bottom: @x-xs;
    }
  }
}

// Contextual variations
.ce-accordion {
  .panel-variant(@gray-lighter; @panel-default-text; transparent; @gray-lighter);
}

.panel-default {
  .panel-variant(@panel-default-border; @panel-default-text; @panel-default-heading-bg; @panel-default-border);
}

.panel-primary {
  .panel-variant(@panel-primary-border; @panel-primary-text; @panel-primary-heading-bg; @panel-primary-border);
}

.panel-success {
  .panel-variant(@panel-success-border; @panel-success-text; @panel-success-heading-bg; @panel-success-border);
}

.panel-info {
  .panel-variant(@panel-info-border; @panel-info-text; @panel-info-heading-bg; @panel-info-border);
}

.panel-warning {
  .panel-variant(@panel-warning-border; @panel-warning-text; @panel-warning-heading-bg; @panel-warning-border);
}

.panel-danger {
  .panel-variant(@panel-danger-border; @panel-danger-text; @panel-danger-heading-bg; @panel-danger-border);
}
