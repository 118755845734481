.ce-job-list {
  .head {
    font-weight: bold;
    padding: (@x-xs/2) 0;
    cursor: default;
    &:after {
      content: ' ';
      display: block;
      border-bottom: 1px solid @gray-border;
      margin: 0 (@grid-gutter-width/2);
    }
  }

  .job {
    padding: 5px 0;
    cursor: default;
    &:hover {
      background-color: @gray-fill;

    }
  }

  .group-title {
    border-top: 1px solid @gray-border;
    font-weight: bold;
    padding: @x-xs 0;
    margin-top: @x-xs;
  }
  .head + .separator {

    .group-title {
      border-top-color: transparent;
      margin-top: 0;
    }

  }

  &.job-list-grouped {
    .location {
      visibility: hidden;
    }
  }

  &.job-list-no-head {
    .head {
      display: none;
    }

    .head + div {
      border-top: 1px solid @gray-border;
    }
  }

  padding-bottom: @x-sm;

}