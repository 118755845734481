.default{

  .ce-seperator+.ce-headline,
  .ce-image+.ce-headline,
  .ce-slideshow+.ce-headline
  {
    >h3,
    >h2,
    >h1
    {
      margin-top: 0;
    }
  }

  &.light{

    &.related-content{
      .ce-seperator+.ce-text{
        h1,h2{
          margin-bottom: 0;
        }
      }
    }

  }
}

