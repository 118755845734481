@color_1: #fff;
@color_2: #4997cd;
@color_3: #666;
@color_4: #468c1f;
@color_5: #e2001a;
@background_color_1: @brand-blue;
@background_color_2: #ffffff;
@background_color_3: @gray-fill;
@badge-size: 80px;
@badge-size-md: 60px;
@badge-size-sm: 25px;
@arrow-size: 20px;
@line-width: 1px;


@sm-arrow-size: 10px;
@sm-badge-size: 60px;
@sm-badge-size-md: 30px;
@sm-badge-size-sm: 10px;


.ce-timeline {
  list-style: none;
  position: relative;

  .clearFix {
    clear: both;
    height: 0;
  }
  .timeline-badge-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    &:before{
      display: block;
      content: ' ';
      width: @line-width;
      height: auto;
      left: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: @brand-blue;
      margin-left: (@line-width/-2);
    }

  }
  .timeline-badge {
    color: @background_color_1;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    border-radius: 50%;
    border: 1px solid @background_color_1;
    background-color: #fff;
    width: @sm-badge-size;
    height: @sm-badge-size;
    line-height: @sm-badge-size;
    margin-left: (@sm-badge-size/-2);
    margin-top: (@sm-badge-size/-2);
  }

  .timeline-badge-major .timeline-badge {
    background-color: @background_color_1;
    color: #fff;
  }
  .timeline-badge-md .timeline-badge {
    width: @sm-badge-size-md;
    height: @sm-badge-size-md;
    font-size: 16px;
    line-height: @sm-badge-size-md;
    margin-left: (@sm-badge-size-md/-2);
    margin-top: (@sm-badge-size-md/-2);
  }
  .timeline-badge-sm .timeline-badge {
    width: @sm-badge-size-sm;
    height: @sm-badge-size-sm;
    line-height: @sm-badge-size-sm;
    margin-left: (@sm-badge-size-sm/-2);
    margin-top: (@sm-badge-size-sm/-2);
    time {
      display: none;
    }
  }

  @media (min-width: @screen-sm-min){
    .timeline-badge{
      width: @badge-size;
      height: @badge-size;
      line-height: @badge-size;
      margin-left: (@badge-size/-2);
      margin-top: (@badge-size/-2);
    }

    .timeline-badge-md .timeline-badge {
      width: @badge-size-md;
      height: @badge-size-md;
      line-height: @badge-size-md;
      margin-left: (@badge-size-md/-2);
      margin-top: (@badge-size-md/-2);
    }
    .timeline-badge-sm .timeline-badge {
      width: @badge-size-sm;
      height: @badge-size-sm;
      line-height: @badge-size-sm;
      margin-left: (@badge-size-sm/-2);
      margin-top: (@badge-size-sm/-2);
    }

    .timeline-movement-empty{
      min-height: @badge-size;
      margin-top: (@badge-size/-2);

      &.timeline-badge-md  {
        min-height: @badge-size-md;
        margin-top: (@badge-size-md/-2);
      }
      &.timeline-badge-sm  {
        min-height: @badge-size-sm;
        margin-top: (@badge-size-sm/-2);
      }

      +.timeline-movement{
        margin-top: (@badge-size/-2);
      }

      +.timeline-movement-empty{
        min-height: 100px;
        margin-top: 0;

        +.timeline-movement{
          margin-top: ((@badge-size - @x-xs)/-1);

          &.timeline-badge-md  {
            margin-top: ((@badge-size-md - @x-xs)/-1);
          }
          &.timeline-badge-sm  {
            margin-top: ((@badge-size-sm - @x-xs)/-1);
          }
        }
      }


    }
  }



  .timeline-movement {
    position: relative;
    .timeline-item {
      padding: 20px 0;
      .timeline-panel {
        background-color: @background_color_3;
        padding: @x-xs;
        position: relative;
        &:before {
          width: 0;
          height: 0;
          left: -@arrow-size;
          content: ' ';
          line-height: 0;
          border-style: solid;
          top: 50%;
          position: absolute;
          display: block;
          margin-top: -@arrow-size;
          border-width: @arrow-size @arrow-size @arrow-size 0;
          border-color: transparent @background_color_3 transparent transparent;
        }

        .headline{
          margin: 0 0 20px;
          font-size: @font-size-large;
          font-weight: 600;
        }

      }
    }

    &:first-child {
      .timeline-badge-container{
        &:before{
          top: 50%;
        }
      }
    }

    &:last-child {
      .timeline-badge-container{
        &:before{
          bottom: 50%;
        }
      }
    }
  }
  .timeline-movement.timeline-movement-top {
    height: 60px;
  }

}
