
.products.level2.default{

  section.light{
    position: relative;
  }

}

.rk-plan,
.products.level2.default{



  .wrapper.paddings{

    position: relative;

    .ce-linklist .info{

      bottom: @x-xs;

      .nav-title{
        display: none;
      }

      .icon{
        margin-left: 10px;
      }

      ul{

        border: 0;

        &:after{
          content: ' ';
          clear: both;
          display: table;
        }
      }

    }

    @media(min-width: @screen-sm-min){
      .ce-linklist{
        margin-top: floor((@x-md+@font-size-base));
        position: static;
        .info{
          position: absolute;
          left: @x-sm;
          li{
            float: left;
            padding-right: @x-sm;
          }
        }

      }

    }
  }
}

.ce-linklist{
  ul{
    list-style: none;
  }
}

.horizontal-list{
  aside.info {
    float: none;
    display: flex;
    width: 100%;
    min-width: auto;
    background-color: #fff;
  }

  .sidebar-nav {
    background: #fff;
    flex-grow: 1;
    ul{
      padding: 0 40px;
      li{
        a{
          padding: 1em 15px;
          max-height: 4.6em;
          text-wrap: none;
          border-bottom: 3px solid transparent;
          transition: border-bottom-color .4s ease ;
          &:hover{
            border-bottom-color: @brand-primary;
          }
        }
      }
    }
  }

  ul:not(.dropdown-menu){

    display: flex;
    flex-wrap: wrap;
    border: 0;
    margin-left: -10px;
    margin-right: -10px;

  }

  .nav-overflow{
    .dropdown-toggle{
      border: 0;
      height: 100%;
      &:hover{
        background-color: #fff;
        color: @brand-primary;
      }
    }
    .dropdown-menu{
      left: auto;
      right: 0;
      margin-right: 0;
    }
  }
}

.icon-nav{
  ul{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 1em;
    border: none;

    li{
      flex: 1;
      flex-grow: 0;


      .icon-wrapper{
        padding: 2rem;
        background-color: #F0F1F2;
        margin-bottom: 10px;
        min-height: 4.5rem;
        transition: padding 0.2s ease;

      }

      .icon{
        width: 4rem;
        height: 4rem;
        float: none;
        display: block;
        margin: 0 auto;
        position: relative;
        transition: transform 0.2s ease;


      }

      span{
        display: block;
        text-align: center;
      }

      &:hover{

        .icon{
          transform: scale(1.1);
        }
      }

    }
  }

}

.bg-style{
  .ce-linklist{
    @media(min-width: @screen-sm-min){
      .info{
        left: (@x-xs+@grid-gutter-width/2) !important;
      }
    }
  }
}
