

@font-face {
	font-family: "Fedra Sans Alt";
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000172-ecc4357c85a8d338b2696d42898b807d.eot");
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000172-ecc4357c85a8d338b2696d42898b807d.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000172-ecc4357c85a8d338b2696d42898b807d.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000172-ecc4357c85a8d338b2696d42898b807d.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000172-ecc4357c85a8d338b2696d42898b807d.svg#Typotheque_webfonts_service") format("svg");
	font-style: normal;
	font-weight: 400;
}
@font-face {
	font-family: "Fedra Sans Alt Book";
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000172-ecc4357c85a8d338b2696d42898b807d.eot");
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000172-ecc4357c85a8d338b2696d42898b807d.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000172-ecc4357c85a8d338b2696d42898b807d.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000172-ecc4357c85a8d338b2696d42898b807d.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000172-ecc4357c85a8d338b2696d42898b807d.svg#Typotheque_webfonts_service") format("svg");
}
@font-face {
	font-family: "Fedra Sans Alt";
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000176-8f3663e5f99e93e2a267f93f25f88d20.eot");
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000176-8f3663e5f99e93e2a267f93f25f88d20.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000176-8f3663e5f99e93e2a267f93f25f88d20.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000176-8f3663e5f99e93e2a267f93f25f88d20.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000176-8f3663e5f99e93e2a267f93f25f88d20.svg#Typotheque_webfonts_service") format("svg");
	font-style: normal;
	font-weight: 800;
}
@font-face {
	font-family: "Fedra Sans Alt Demi";
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000176-8f3663e5f99e93e2a267f93f25f88d20.eot");
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000176-8f3663e5f99e93e2a267f93f25f88d20.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000176-8f3663e5f99e93e2a267f93f25f88d20.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000176-8f3663e5f99e93e2a267f93f25f88d20.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000176-8f3663e5f99e93e2a267f93f25f88d20.svg#Typotheque_webfonts_service") format("svg");
}
@font-face {
	font-family: "Fedra Sans Alt";
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.eot");
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.svg#Typotheque_webfonts_service") format("svg");
	font-style: normal;
	font-weight: 600;
}
@font-face {
	font-family: "Fedra Sans Alt";
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.eot");
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.svg#Typotheque_webfonts_service") format("svg");
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: "Fedra Sans Alt Medium";
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.eot");
	src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-024664-007761-000180-49a567aff12864182e090f71a9baed9c.svg#Typotheque_webfonts_service") format("svg");
}


