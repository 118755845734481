@import (reference) "../mixins/background-variant";

.default .light{
  >.wrapper.paddings{
    .ce-column{

      margin-bottom: @x-xs;

      &.bg-style{

        >.wrapper>.flex-row,
        >.flex-row
        {
          padding: @x-xs;

          >.no-padding-top{
            margin-top: -@x-xs;
          }
          >.no-padding-top{
            margin-bottom: -@x-xs;
          }
          >.no-padding{
            margin-bottom: -@x-xs;
            margin-top: -@x-xs;
          }

          >.no-padding-right{
            margin-right: -20px;
            margin-left: 20px;
            &.ce-image{
              .wrapper{
                height: 100%;
                background-color: inherit;
                margin-right: -20px;
              }
            }
          }

          >.no-padding-left{
            margin-left: -20px;

            &.ce-image{
              margin-left: 0;
              margin-right: 0;
              .wrapper{
                height: 100%;
                background-color: inherit;
                margin-left: -20px;
              }
            }
          }

          @media (min-width: @screen-sm-min){
            >.sm-no-padding-top{
              margin-top: -@x-xs;
            }
            >.sm-no-padding-top{
              margin-bottom: -@x-xs;
            }
            >.sm-no-padding{
              margin-bottom: -@x-xs;
              margin-top: -@x-xs;
            }
            >.sm-no-padding-right{
              margin-right: -20px;
              margin-left: 20px;
              &.ce-image{
                .wrapper{
                  height: 100%;
                  background-color: inherit;
                  margin-left: -20px;
                }
              }
            }

            >.sm-no-padding-left{
              margin-left: -20px;
              margin-right: 20px;
              &.ce-image{
                margin-left: 0;
                margin-right: 0;
                .wrapper{
                  height: 100%;
                  background-color: inherit;
                  margin-left: -20px;
                }
              }
            }
          }
        }
      }
    }
  }
}