//
// Utility classes
// --------------------------------------------------


// Floats
// -------------------------

.clearfix {
  .clearfix();
}
.center-block {
  .center-block();
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}


// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  .text-hide();
}

.text-white{
  color: #fff!important;
}


// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
}


// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}


.whitespace(@positions);
.whitespace(@positions,padding);
.whitespace(@positions,margin);

@media (min-width: @screen-sm-min) {
  .whitespace(@positions,margin,sm-);
  .whitespace(@positions,padding,sm-);
}
//.whitespace(@positions, padding);
