.paddings-sm{
  padding: 20px 20px 0 20px;
}

.paddings-lg{
  padding: 7.2916666666% @padding-large 0 @padding-large;
}
.wrapper-light{
  background-color: #fff;
}
.ce-slideshow {
  &.slideshow-nopadding{
    > .swiper-container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    margin-bottom: 0;
  }
}


.chapter {
  padding: 0 20px;

  .chapter-gradient {
    position: relative;
  }

  .chapter-gradient > .wrapper {
    background-color: #dee3e7;
    margin-right: -40px;
    margin-bottom: -30px;
    padding-right: 40px;
    padding-bottom: 30px;
  }

  .col-sm-6,
  .col-sm-3 {
    padding-right: 20px;
    padding-left: 20px
  }

  .flex-col-sm-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .section-content {
    padding: 0 10px;

    .headline {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

}

.rk-magazine {



  .page-image{

    @media (min-width: @screen-sm-max) {
      .ce-image {
        height: 364px;
      }
    }

    .ce-teaserbox.news,
    .ce-teaserbox
    {
      .teaser-box .teaser-image {
        &:before {
          padding-top: 30%;
        }
        &:after{
          display: none;
        }

        img{
          width: 100%;
          object-fit: cover;
        }
      }

      .teaser-content{
        padding: @x-xs;
        .headline{
          margin-top: 0;
          color: @brand-primary;
        }
      }

      margin-bottom: 0;
    }

    .ce-slideshow{
      .swiper-container{
        padding: 0 0 44px 0!important;
      }
      .ce-teaserbox.news{

        .teaser-link{
          display: block;
          padding-top: @x-xs;
          .btn{
            color: @btn-primary-color;
            background-color: @btn-primary-bg;
            border-color: @btn-primary-bg;
            padding: @padding-base-vertical @padding-base-horizontal;
          }
        }
      }

    }
  }

  .ce-teaserbox.news{

    .teaser-box .teaser-image {

      &:after{
        display: none;
      }
    }

    .news-date{
      display: none;
    }

    .teaser-link{
      display: none;
    }
  }

  &.rk-magazine-article {

    .page-description {
      h1 {
        font-size: ceil((@font-size-base * 3.4));
        color: @brand-primary;
        margin-bottom: 0;
      }

      h2 {
        margin-bottom: @x-xs;
      }

      aside.info {
        position: static;
        float: none;
      }

      .ce-linklist {

        @media (max-width: @screen-xs-max) {

          &.visible-xs {
            display: block !important;

            .btn-toggle {
              display: none;
            }

          }

          &.hidden-xs {
            .btn-toggle {
              display: none;
            }
          }

          .sidebar-nav {
            position: static;

            .nav-title {
              display: block;
            }

            ul {
              display: block;

              li {
                border: 0;
              }
            }
          }
        }

        .nav-title {
          margin-bottom: 0;

          span {
            padding: 0;
            border: 0;
            color: @brand-gray;
          }
        }

        .sidebar-nav {
          > ul {
            border: 0;
            padding: 0;
            color: @brand-primary;
          }

          a {
            color: @brand-primary;
            margin: 0;
            padding: 0;
            line-height: 1.5;
            font-weight: normal;
          }
        }

        &.list-ordered ul {
          list-style: decimal;
          margin-left: 1em;
        }
      }

      .article-info {
        color: @brand-gray;
        line-height: 1.5;
      }
    }

    .page-image {

      .ce-image{
        height: 300px!important;
      }

      @media (max-width: @screen-sm-max) {
        height: auto!important;
        margin-top: @x-xs;
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  .company,
  .company.news,
  .rk-level2 {
    .page-title {
      h1, h2, h3 {
        font-size: 36px;
        margin-top: 0;
      }
    }

    .light .paddings {
      h1, h2, h3 {
        font-size: 30px;
      }
    }
  }

  .products{

    .light .paddings {
      h1, h2, h3 {
        font-size: 30px;
      }
    }
  }
}

.no-text.show-line {
  .chapter-headline {
    padding: 0;
  }
}


.nav-title,
.chapter-headline {

  .show-line {
    border-top: 1px solid #ccc;
    padding-top: 20px;

  }

  margin-bottom: 20px;

  h1, h2, h3, span {
    line-height: 23px;
    font-size: 14px!important;
    margin: 0;
    display: inline-block;
    border: 1px solid;
    padding: 2px 10px 0px 10px;
    font-weight: bold;

  }

  + h1,
  + h2,
  + h3 {
    margin-top: 0;
  }
}

.show-line {
  &.chapter-headline,
  .chapter-headline {
    padding-top: 20px;
    border-top: 1px solid @gray-lighter;
  }
}

.navbar-default {
  + section.page-image {
    margin-top: -85px;
  }

  + default.rk-level1 {
    margin-top: -20px;
  }
}

.page-image{
  + * {
    padding-top: 60px;
  }
  .products.level1 &{
    margin-bottom: @x-sm;
  }
}

.start .rk-tabs{
  padding-top: @x-xs;
  @media(max-width: @screen-xs-max){
    margin-top: @x-sm;
  }
}


.default {


  &.no-paddings {
    > .wrapper.paddings {
      padding: 0 !important;
    }
  }

  header {
    .page-title {
      h1, h2 {
        margin-bottom: 15px;
      }
      .ce-text {
        h1, h2 {
          margin-bottom: 15px;
        }
        p {
          margin-bottom: 0;
        }
        margin-bottom: 30px;

      }
    }
  }

  &.products{
    &.level2,
    &.level1{
      h1+h2{
        font-size: 30px;
      }
    }
  }

  &.light {
    background-color: #ffffff;

    > .wrapper {
      padding: 0 (@grid-gutter-width/2);

      &.paddings {
        .paddings-sm;
      }

    }

    &:last-child {
      padding-bottom: 60px;
    }
  }

  .row:first-child div[class*="col"]:first-child,
  .flex-row:first-child div[class*="col"]:first-child {
    > h1:first-of-type,
    > h2:first-of-type,
    > h3:first-of-type {
      margin-top: 0;
    }
  }


}

.footer .default {

  &.newsletter{
    background-color: #e3eaf2;
  }

  .related-title {
    h1, h2 {
      margin-bottom: 15px;
    }
    .ce-text {
      h1, h2 {
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 0;
      }
      margin-bottom: 30px;

    }
  }

}


.rk-tabs {

  &.light{
    padding-left: 20px;
    padding-right: 20px;
  }

  .teaser-box-wrapper,
  .teaser-box-container {
    margin-bottom: 20px;
  }

  .tab-pane .wrapper.paddings{
    padding: 0!important;
  }

  .ce-teaserbox{
    margin-bottom: @x-xs!important;
  }

  .h3{
    margin-bottom: 0.5rem;
  }
}

@media (min-width: @screen-sm-min) {
  .chapter {
    .chapter-gradient > .wrapper {
      background-color: #dee3e7;
      position: absolute;
      margin-top: -30px;
      margin-right: -30px;
      right: 0;
      left: 0;
      margin-left: 10px;
      bottom: 0;
      height: auto;
      top: 0;
      margin-bottom: -30px;
      padding: 30px 40px 30px 0;
    }
  }
}

.footer {

  .logo figure{
    height: 84px;
    margin-left: -15px;
    margin-top: -20px;
    img{
      max-height: 100%;
    }
  }

  .follow-social,
  #footer-socials{
    margin: 0;
    ul{
      display: flex;
      grid-gap: 0;
      margin:0;
      li {
        padding: 0;
        a{
          padding: 0;
          .icon-wrapper + span,
          i + span {
            display: none;
          }

          .icon-wrapper{
            min-height: auto;
            padding: 0;
            background: transparent;
          }

          .icon {
            width: 36px;
            height: 36px;
            display: block;
          }
        }

      }
    }

  }

  .ce-text{
    line-height: 1.5em;

    a{
      &:hover{
        text-decoration: none;
      }
    }

    p{
      margin: 0;
      padding: 0.2em 0;
    }

    ul{
      li{
        padding: 0.2em 0;
        &:before{
          display: none;
        }
      }
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;

      + p{
        margin-top: 0.5em;
      }
    }
  }

  .nav-wrapper{

    >ul{
      >li{
        +li{
          >.headline{
            display: block;
            padding-top: @x-xs;
          }
        }
      }
    }
    &.level1{
      >ul{
        >li{
          >a{
            &.no-subnav{
              font-weight: 600;
            }
          }
        }
      }
    }

    +.nav-wrapper{
      margin-top: @x-xs;
    }
  }

  padding-top: 30px;
  margin-top: 85px;

  &.footer-news,
  &.footer-related {
    background-color: #ffffff;
  }

  padding-bottom: 85px;

  &.footer-default {
    color: @footer-text;
    background-color: @footer-bg;
    cursor: default;


    .headline {
      font-weight: 600;
    }

    a {
      color: inherit;
    }

    .ce-text b,
    .headline {
      font-weight: 600;
    }

    .nav {
      li {

        font-size: 13px;
        > a {
          padding: 2px 0;
        }
      }
    }





    .icon-nav {
      margin-top: 20px;
      li {
        float: left;
        padding-right: 5px;
        padding-bottom: 5px;
      }
    }


  }

  &.footer-content-newsletter{
    background-color: #E3E8F0;
    padding-bottom: 30px;

    &.footer-content-newsletter-large{
      background-color: #D9EBF8;

      .ce-text{
        margin-bottom: 1em;
      }
    }

    .headline{
      font-size: @font-size-h3;
      font-family: 'Fedra Sans Alt Demi';
      color: #000;
      font-weight: normal;
      margin: 0;
    }

    .newsletter-icon figure {
      width: 52px;
      max-height: 52px;

      img{
        max-height: 52px;
      }
    }



    @media(min-width: @screen-md-min){

      .headline{
        font-size: 26px;
      }

      .newsletter-icon figure {
        float: right;
      }
    }


    .newsletter-action .btn{
      color: #fff;
      padding-right: @x-sm;
      padding-left: @x-sm;
    }

    + .footer-default{
      margin-top: 0px;
      background-color: #FEFEFE;

    }
  }




}

@media(min-width: @screen-md-min){
  .sticky-icons{
    content: '';
    position: fixed;
    bottom: 30%;
    right: 0;
    z-index: 1000;


    li {
      text-align: right;
      padding: 2px 0;

      a {
        min-width: 52px;
        height: 52px;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-flex;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, .10);


        span {
          line-height: 32px;
          padding-left: 1em;
          margin-right: -200px;
          box-sizing: content-box;
          overflow: hidden;
          transition: margin-right 0.2s ease;
        }

        &:hover{
          span {
            margin-right: 0;
          }
        }

        .icon {
          width: 32px;
          height: 32px;
          display: inline-block;
        }



      }
    }

  }
}

.default {

}


.rk-magazine-article,
.company,
.company.news,
.rk-level2
{
  .default {

    &.light {

      &.related-content {
        margin-top: 20px;

        @media (min-width: @screen-sm-min) {
          > .wrapper.paddings {
            padding-top: 30px;
          }
        }
      }

      + .default.light {
        .wrapper.paddings {
          padding-top: 0;
        }
      }

      @media (min-width: @screen-sm-min) {
        > .wrapper.paddings {
          padding: 7.2916666666% @padding-large 0 @padding-large;

        }
      }

    }




  }
}

.dummy {
  border: 1px solid;
}
.default.light > .wrapper.paddings-xs,
.wrapper.paddings-xs {
  padding: 0 40px
}

.navbar-default {
  + .rk-level1 {
    margin-top: -15px;
  }
}

.rk-magazine,
.products.level2,
.rk-level1 {
  .default {

    header {
      margin-bottom: 20px;
    }

    &.light {
      + a + .default.light:not(.default.mt-0),
      + .default.light:not(.default.mt-0) {
        .wrapper.paddings {
          margin-top: 20px;
        }
      }

      > .wrapper {

        @media (min-width: @screen-sm-min) {
          @wrapper-pt: 30px;
          @wrapper-pb: 30px;
          &.paddings {
            padding: @wrapper-pt 40px @wrapper-pb 40px;

          }

          .flex-row{

            >.no-padding-top{
              margin-top: -@wrapper-pt;
            }
            >.no-padding-bottom{
              margin-bottom: -@wrapper-pb;
            }
            >.no-padding{
              margin-bottom: -@wrapper-pb;
              margin-top: -@wrapper-pt;
            }

            @media (min-width: @screen-sm-min){
              >.sm-no-padding-top{
                margin-top: -@wrapper-pt;
              }
              >.sm-no-padding-bottom{
                margin-bottom: -@wrapper-pb;
              }
              >.sm-no-padding{
                margin-bottom: -@wrapper-pb;
                margin-top: -@wrapper-pt;
              }
            }
          }
        }

      }
    }
  }

  .page-links{
    margin-top: @x-md;
  }
}


.products{
  &.level1 {
    .page-image{
      .ce-slideshow{
        .ce-slideshow.slideshow-nopadding
      };
    }
  }

  &.level2{
    .default{

      header{
        margin-bottom: 0;
      }

    }
  }
}

.page-wrapper {
  margin-top: 90px;
  @media (min-width: @screen-sm-min) {
    margin-top: 140px;
  }

}

.start {
  .page-wrapper {
    margin-top: 90px;
  }

  .page-image{
    .ce-slideshow{
      .ce-slideshow.slideshow-nopadding
    }
  }

}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

#eu-cookie-law{
  position: fixed;
  bottom: 0;
  background-color: rgba(0,0,0,.8);
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 1000;
  p{
    color: #fff;
  }

  .btn-default{
    color: #ffffff;
    background-color: transparent;
    border: 2px solid #fff;
    &:hover{
      border: 2px solid @brand-blue;
    }
  }
}


.news-article{

  header{
    .page-image{
      >.wrapper{
        position: relative;
        background-color: #fff;
      }
      .ce-slideshow{
        margin: 0;
        padding: 0!important;
        .swiper-pagination{
          display: none;
        }

        .swiper-slide{
          background-color: #fff!important;
        }

        .swiper-thumbnail-container{
            display: none;
            overflow: hidden;
            .mobile-accordion{
              height:100%;
              text-align: center;
                div,figure {
                  height:100%;
                }

                img{
                  max-height:100%;
                }
            }

            .swiper-button-prev{
              left: 50%;
              top: @x-xs;
              margin-left: -10px;
              border-width: 0 20px 20px 20px;
              border-color: transparent transparent #4d4d4d transparent;
              margin:0;
            }

            .swiper-button-next{
              left: 50%;
              bottom: @x-xs;
              border-width: 20px 20px 0 20px;
              border-color: #4d4d4d transparent transparent transparent;
              top: auto;
              margin-left: -10px;
              margin:0;
            }

        }

        @media (min-width: @screen-sm-min){
          .swiper-container{
            flex-basis: percentage((8 / @grid-columns));
            width: percentage((8 / @grid-columns));
            float: left;
          }

          .swiper-thumbnail-container{
            display: block;
            flex-basis: percentage((4 / @grid-columns));
            width: percentage((4 / @grid-columns));
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            height: 100%;
            padding: @x-md @x-md;
          }
        }
      }
    }
  }

}

.location{
  &.ce-accordion{
    .panel-heading span.icon-flag{
      width: percentage((3/@grid-columns));
    }
  }
}


.tt-menu{
  background-color: #fff;
  right:0;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.tt-suggestion:hover{
  background-color: #f0f0f0;
}
.tt-suggestion{
  padding: 10px 5px;
  cursor: pointer;
}


.search-local-dealer{
  background-color: #fff;
  padding-bottom: @x-sm;
}

.dummy{
  color: red;
}

.kfv .rk-tabs{
  display: none;
}

.login{

  max-width: 480px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 3px 30px 0 rgba(0,0,0,.15);

  .panel-body{
    padding: @x-xs;
    @media(min-width: @screen-sm){
      padding: @x-xs;
    }
  }



  .logo{
    text-align: center;
    img{
      max-height: 100px;
      padding-bottom: @x-xs;
    }
  }
}

.hidden-rk + .rk-tabs{
  display:none!important;
}
